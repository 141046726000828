import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Roles, UserProfile } from '../models/profile.models';
import { HttpService } from './http.service';
import jwtDecode from "jwt-decode";
import { environment } from 'environments/environment';
import moment from 'moment'

var ProfileService_DOMAINS_CONFIG = "__USERS";
var ProfileService_USER_ROFILE_DOMAIN = "__PROFILE";

@Injectable()
export class ProfileService {
	private baseUrl = environment.services.apiBasePath + "/" + ProfileService_DOMAINS_CONFIG + "/"+ProfileService_USER_ROFILE_DOMAIN;//environment.services.restaurantService + "/" + ProfileService_DOMAINS_CONFIG;
	
	public profile$: ReplaySubject<UserProfile> = new ReplaySubject<UserProfile>(1);
	private _profile: UserProfile;
	private get profile(): UserProfile {
		return this._profile;
	}
	private set profile(profile: UserProfile) {
		this._profile = profile;
		this.profile$.next(profile);
	}
	constructor(private _oauthService: OAuthService, protected _httpService: HttpService) {
	}
	public loadUserProfile = (): Observable<UserProfile> => {
		return from(this._oauthService.loadUserProfile())
		.pipe(
			mergeMap((result: any) => {
				var decoded = <any>jwtDecode(this._oauthService.getAccessToken());
				var ret = new UserProfile();
				console.log(decoded);
				
				ret.email = decoded.email;
				ret.firstName = decoded.given_name;
				ret.lastName = decoded.family_name;
				ret.userId = decoded.sub;
				ret.roles = decoded.realm_access.roles || [];
				return this.getUserProfileByField("userId", ret.userId).pipe(
					catchError((r) => {
						return this.saveOrUpdateProfile(ret)
					}),
					map((res) => {
						console.log("sono in MAP", res);
						return res;
					}
					)
				);
			}))
		.pipe(tap(result => {
			console.log("profilo ricevuto:", result);
			
			this.profile = result;
		}));
	}

	public saveProfile = (profile: UserProfile): Observable<UserProfile> => {
		return this.loadUserProfile().pipe(mergeMap((result: UserProfile) => {
			result.firstName = profile.firstName;
			result.lastName = profile.lastName;
			result.email = profile.email;
			return this._httpService.put(`${this._oauthService.issuer}/account/profile`, result);
		})).pipe(tap(result => {
			this.profile = result;
		}));
	}

	public isInRole = (role: Roles): boolean => {
		return this._profile.roles.includes(role.toLowerCase());
	}

	public getLoggedProfile = (): Observable<UserProfile> => {
		return of(this.profile);
	}

	// public importUserData = () => {
	// 	this.getAll().subscribe((result) => {
	// 		result.forEach((user) => {
	// 			this._httpService.post(environment.services.apiBasePath + "/" + ProfileService_DOMAINS_CONFIG + "/"+ProfileService_USER_ROFILE_DOMAIN, user).subscribe(()=>{});
	// 		})
	// 	})
	// }

	public getAll = (fieldsName?: string[]): Observable<UserProfile[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(this.baseUrl  + queryParams, "json");
	}
	public getProfile = (id: any): Observable<UserProfile> => {
		let url = this.baseUrl + "/" + id;
		return this._httpService.get(url);
	}
	public getUserProfileByField = (fiendId: string, value: any): Observable<UserProfile> => {
		let url = this.baseUrl + "/" + fiendId + "/" + value;
		return this._httpService.get(url);
	}
	
	public deleteProfile = (id: any): Observable<UserProfile> => {
		let url =  this.baseUrl + "/" + id;
		return this._httpService.delete(url);
	}
	public saveOrUpdateProfile = (profile: UserProfile, toNotify: boolean = false): Observable<UserProfile> => {
		let url = this.baseUrl;
		profile.lastUpdateDate = moment().format("DDMMYYYY");
		if (profile.id)
			return this._httpService.put(url + "/"+profile.id + "?notify="+toNotify, profile, "json");
		return this._httpService.post(url + "?notify="+toNotify, profile,"json");
	}
	public certifyRestaurant = (data: any): Observable<any> => {
		let url = environment.services.blockChainService;
		return this._httpService.post(url,data,"json");
	}

	public getImageHash(imageId): Observable<string>{
		return this._httpService.get(environment.services.mediaContentsBasePath + "/"+imageId + "/hash", "text");
	}

	public updatePassword(newPasswordRequest: {
		temporary: boolean,
		value: string,
		type: string
	}): Observable<string>{

		
		return this._httpService.put(environment.services.updatePasswordUrl.replace("{id}", this.profile.userId), newPasswordRequest,"json");
	}
}

