<base-page>
	<ng-container page-body>
		<div class="row">
			<div class="col-1">&nbsp;</div>
			<div class="col-10">
		<form [formGroup]="formGroup" *ngIf="formGroup">



		<div class="row"  style="margin-top: 20px;">
			<div class="col-3 d-flex flex-sm-row flex-column justify-content-left">
					<img *ngIf="profileImage" src="{{profileImage}}" alt="profile-img" class="rounded mr-3" height="64" width="64">
				<span>
					{{firstName}} {{lastName}}<br>
					<a  (click)="logout()">Logout</a>
					<!-- <span class="button" > </span> -->
				</span>
					
				
			</div>
			<div class="col-6 d-flex flex-sm-row flex-column justify-content-center">
				<h1 class="color-primary">Il Tuo Profilo</h1>
			</div>
			<div class="col-3 d-flex flex-sm-row flex-column justify-content-end">
				<div style="height: 100px; width: 200px;">
					<img src="{{logoImage}}" style="max-width: 200px;">
				</div>
			
			</div>
		</div>
		<div>
			
<!-- Account Settings starts -->
<div class="row">
	<div class="col-md-3 mt-3">
		<!-- Nav tabs -->
		<ul class="nav flex-column nav-pills" id="myTab" role="tablist">
			<!-- <li class="nav-item">
				<a class="nav-link" href="javascript:;" [ngClass]="{active: activeTab === 'general'}"
					(click)="setActiveTab('general')" id="general-tab" data-toggle="tab" role="tab"
					aria-controls="general" aria-selected="true">
					<i class="ft-settings mr-1 align-middle"></i>
					<span class="align-middle">Account</span>
				</a>
			</li> -->
			
			<li class="nav-item">
				<a class="nav-link" href="javascript:;" [ngClass]="{active: activeTab === 'personalInfo'}"
					(click)="setActiveTab('personalInfo')" id="personalInfo-tab" data-toggle="tab" role="tab"
					aria-controls="personalInfo" aria-selected="false">
					<i class="ft-info mr-1 align-middle"></i>
					<span class="align-middle">informazioni personali</span>
				</a>
			</li>
			
			<!-- <li class="nav-item">
				<a class="nav-link" href="javascript:;" [ngClass]="{active: activeTab === 'contacts'}"
					(click)="setActiveTab('contacts')" id="contacts-tab" data-toggle="tab" role="tab"
					aria-controls="contacts" aria-selected="false">
					<i class="ft-link mr-1 align-middle"></i>
					<span class="align-middle">Contatti</span>
				</a>
			</li> -->
			<li class="nav-item" *ngIf="userProfile && userProfile.isCompanyProfile">
				<a class="nav-link" href="javascript:;" [ngClass]="{active: activeTab === 'company'}"
					(click)="setActiveTab('company')" id="company-tab" data-toggle="tab" role="tab"
					aria-controls="company" aria-selected="false">
					<i class="ft-home mr-1 align-middle"></i>
					<span class="align-middle">Le tue Aziende</span>
				</a>
			</li>


			<li class="nav-item">
				<a class="nav-link" href="javascript:;" [ngClass]="{active: activeTab === 'wallet-info'}"
					(click)="setActiveTab('wallet-info')" id="wallet-info-tab" data-toggle="tab" role="tab"
					aria-controls="wallet-info" aria-selected="false">
					<i class="fas fa-wallet mr-1 align-middle"></i>
					<span class="align-middle">Wallet</span>
				</a>
			</li>

			<li class="nav-item" *ngIf="isAdmin && ((dashboardsEnabled && dashboardsEnabled.length>0) || (acrEnabled && acrEnabled.length>0))">
				<a class="nav-link" href="javascript:;" [ngClass]="{active: activeTab === 'dashboard-list'}"
					(click)="setActiveTab('dashboard-list')" id="dashboard-list-tab" data-toggle="tab" role="tab"
					aria-controls="dashboard-list" aria-selected="false">
					<!-- <i class="fas fa-wallet mr-1 align-middle"></i> -->
					<i class="fas fa-pager mr-1 align-middle"></i>

					<span class="align-middle">Le tue dashboard</span>
				</a>
			</li>

			<li class="nav-item" >
				<a class="nav-link" href="javascript:;" [ngClass]="{active: activeTab === 'nft-list'}"
					(click)="setActiveTab('nft-list')" id="nft-list-tab" data-toggle="tab" role="tab"
					aria-controls="nft-list" aria-selected="false">
					<i class="ft-award mr-1 align-middle"></i>

					<span class="align-middle">I tuoi NFT
						<span class="badge badge-danger badge-pill" *ngIf="availableNftToClaim"><i class="ft-bell"></i></span>
					</span>
				</a>
			</li>

		</ul>
	</div>
	<div class="col-md-9">
		<!-- Tab panes -->
		<div class="card">
			<div class="card-content">
				<div class="card-body">
					<div class="tab-content">
						<!-- General Tab -->
						<!-- <div class="tab-pane" [ngClass]="{active: activeTab === 'general'}" id="general"
							role="tabpanel" aria-labelledby="general-tab">
							
							<div class="row">
								<div class="col-12">
									<input-string formControlName="firstName" label="Nome" [disabled]="true">
									</input-string>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<input-string formControlName="lastName" label="Cognome" [disabled]="true">
									</input-string>
								</div>
							</div>
							
							<div class="row">
								<div class="col-12">
									<input-string formControlName="email" label="indirizzo Email" [disabled]="true">
									</input-string>
								</div>
							</div>
						</div> -->

						<!-- Personal Info Tab -->
						<div class="tab-pane" [ngClass]="{active: activeTab === 'personalInfo'}" id="personalInfo" role="tabpanel" aria-labelledby="personalInfo-tab">
							<div class="row">
								<div class="col-12">
									<input-file notShowAlertOnFileDelete="true" formControlName="avatarId" [possibileMediaTypes]="possibileMediaTypes" label="Immagine del profilo"></input-file>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<input-string formControlName="firstName" label="Nome" [disabled]="true">
									</input-string>
								</div>
								<div class="col-6">
									<input-string formControlName="lastName" label="Cognome" [disabled]="true">
									</input-string>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<input-string formControlName="email" label="indirizzo Email" [disabled]="true">
									</input-string>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<input-slide formControlName="isCompanyProfile" label="Usa il mio account come profilo aziendale" [horizontal]="true"> </input-slide>
								</div>
							</div>
							<hr class="mt-1 mt-sm-2">

							
							<div class="row">
								<div class="col-12">
									<input-string formControlName="fiscalCode" label="codice fiscale">
									</input-string>
								</div>
							</div>
							<form [formGroup]="address">

								<div class="row">
									<div class="col-12">
										<input-string formControlName="address" label="indirizzo">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-4">
										<input-string formControlName="city" label="Città/comune">
										</input-string>
									</div>
									<div class="col-4">
										<input-string formControlName="cap" label="CAP">
										</input-string>
									</div>
									<div class="col-4">
										<input-string formControlName="province" label="Provincia">
										</input-string>
									</div>
								</div>

								<!-- <div class="row">
									<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
										<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!personalInfoValid" (click)="savePersonalInfo()">Salva</button>
									</div>
								</div> -->
							</form>
							<hr class="mt-1 mt-sm-2">
							<form [formGroup]="contacts">	
								<div class="row">
									<div class="col-12">
										<input-string formControlName="mobilePhone" label="Numero di cellulare">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="fixedPhone" label="Telefono fisso">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="pec" label="PEC">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="alternativeEmail" label="indirizzo email alternativo">
										</input-string>
									</div>
								</div>
								<!-- <div class="row">
									<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
										<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!contactsValid" (click)="saveContacts()">Salva</button>
									</div>
								</div> -->
							</form>

							<div class="row">
								<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
									<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="showUpdatePassword()">Modifica password</button>
									<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!contactsValid || !personalInfoValid" (click)="saveUserProfile()">Salva</button>
									
								</div>
							</div>
						</div>


						<!-- contacts Tab -->
						<!-- <div class="tab-pane" [ngClass]="{active: activeTab === 'contacts'}" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
							<form [formGroup]="contacts">	
								<div class="row">
									<div class="col-12">
										<input-string formControlName="mobilePhone" label="Numero di cellulare">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="fixedPhone" label="Telefono fisso">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="pec" label="PEC">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="alternativeEmail" label="indirizzo email alternativo">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
										<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!contactsValid" (click)="saveContacts()">Salva</button>
									</div>
								</div>
							</form>
						</div> -->

						<!-- company Tab -->
						<div class="tab-pane" [ngClass]="{active: activeTab === 'company'}" id="company" role="tabpanel" aria-labelledby="company-tab">
							<!-- <form [formGroup]="company">	
								<div class="row">
									<div class="col-12">
										<input-string formControlName="company" label="Nome azienda">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<input-string formControlName="organization" label="Settore aziendale">
										</input-string>
									</div>
								</div>
								
							</form>
							<form [formGroup]="companyAddress">	
								<div class="row">
									<div class="col-12">
										<input-string formControlName="address" label="indirizzo">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-4">
										<input-string formControlName="city" label="Città/comune">
										</input-string>
									</div>
									<div class="col-4">
										<input-string formControlName="cap" label="CAP">
										</input-string>
									</div>
									<div class="col-4">
										<input-string formControlName="province" label="Provincia">
										</input-string>
									</div>
								</div>
								<div class="row">
									<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
										<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!companyValid" (click)="saveCompany()">Salva</button>
									</div>
								</div>
							</form> -->
							<div *ngIf="!isAdmin && userCompanies && userCompanies.length == 0">
								<h4>Non hai ancora associato nessuna azienda</h4>
								<div class="row text-right">
									<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
										<button type="button" class="btn btn-primary mr-sm-2 mb-1"  (click)="goToCompany(null)">Crea una nuova Azienda</button>
									</div>
								</div>
							</div>
							<div class="row mb-3" *ngFor="let company of userCompanies" (click)="goToCompany(company)" style="cursor: pointer;">
								<div class="col-3">
									<img src="{{getCompanyImage(company)}}" style="max-width: 100px;">
								</div>
								<div class="col-9">
									<h4>{{company.name}}</h4>
									<p>{{company.address}}, {{company.cap}} {{company.city}} ({{company.province}})</p>
									<p *ngIf="company.phoneNumber">Telefono {{company.phoneNumber}}</p>
									<p *ngIf="company.email">Indirizzo Email {{company.email}}</p>
								</div>
							</div>
							<div *ngIf="!isAdmin && userCompanies && userCompanies.length > 0 && !walletCreating && !walletAlreadyRequired">
								<h4>Attenzione! Per poter certificare i dati hai bisogno di creare un wallet.</h4>
							</div>

							<div *ngIf="isAdmin">
								<div class="row text-right">
									<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
										<button type="button" class="btn btn-primary mr-sm-2 mb-1"  (click)="goToCompany(null)">Crea una nuova Azienda</button>
									</div>
								</div>
							</div>
						</div>

						<!-- Social Links Tab -->
						<div class="tab-pane" [ngClass]="{active: activeTab === 'wallet-info'}" id="wallet-info" role="tabpanel" aria-labelledby="wallet-info-tab">
							<div *ngIf="walletCreating">
								<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
								<div class="row">
									
									<div class="col-12 d-flex flex-sm-row flex-column justify-content-center" *ngIf="!privateKey">
										<p>Sto creando il Wallet... attendi qualche secondo!</p>
										<p>&nbsp;</p>
										<p>&nbsp;</p>
									</div>
								</div>
								<div class="row">
									
									<div class="col-12 d-flex flex-sm-row flex-column justify-content-center" *ngIf="!privateKey">
										<img src="assets/img/spinner.gif" class="spinner">
									</div>
								</div>
								
								
							</div>
							<div *ngIf="!walletCreating">
								<div  *ngIf="walletAlreadyRequired">
									<div *ngIf="privateKey">
										<h3>La tua chiave segreta è:</h3>
										<h4>{{privateKey}}</h4>
										<h4>Mi raccomando. Conservala in un luogo protetto e non fornirla a nessuno.</h4>
									</div>
									<div *ngIf="!privateKey">
										<h3>Di seguito i dati del tuo wallet.</h3>
										<h4>la tua chiave pubblica è: {{userProfile.walletInfo.pubKey}}</h4>
										<h4>L'ultimo aggiornamento disponibile risale al {{userProfile.walletInfo.creationDate | datetime}}</h4>
										<p>&nbsp;</p>
										<p *ngIf="delegationEnabled">
											<a href="{{delegationUrl}}" target="_new">
												Delegci per i vari trasferimenti di proprietà degli NFT ai singoli utenti che ne fanno richeista

											</a>
										
										</p>
										<p>&nbsp;</p>
									</div>

									<div class="row">
										<div class="col-12 d-flex flex-sm-row flex-column justify-content-end" *ngIf="privateKey">
											<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="saveOnFile()">Salva la chiave segreta sul disco</button>
										</div>
										<div class="col-12 d-flex flex-sm-row flex-column justify-content-end" *ngIf="!privateKey">
											<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="createNewWallet()">Crea un nuovo Wallet</button>
										</div>
									</div>
								</div>
								<div  *ngIf="!walletAlreadyRequired">
									<h2>Wallet da creare!</h2>
									<h3>Non ci risultano wallet associati alla tua utenza.</h3>
									<div class="row">
										<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
											<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="createNewWallet()">Crea un nuovo Wallet</button>
										</div>
									</div>
								</div>
							</div>
							
						</div>

						<!-- dashboard-list -->
						<div class="tab-pane" [ngClass]="{active: activeTab === 'dashboard-list'}" id="dashboard-list" role="tabpanel" aria-labelledby="dashboard-list-tab">
							<h2>Elenco delle dashboard a cui hai accesso</h2>
							<p *ngFor="let route of dashboardsEnabled">
								<a  [routerLink]="route.routerLink">{{route.label}}</a>

								<!-- <a href="{{route.routerLink}}" >{{route.label}}</a> -->
							</p>
							<p *ngFor="let route of acrEnabled">
								<!-- <a  [routerLink]="route.routerLink">{{route.label}}</a> -->

								<a (click)="goToEnabledDashboard($event, route.navigationPath)" >{{route.label}}</a>
							</p>

							
						</div>


						<!-- NFT List-->
						<div class="tab-pane" [ngClass]="{active: activeTab === 'nft-list'}" id="nft-list" role="tabpanel" aria-labelledby="nft-list-tab">
							<h2 *ngIf="userClaimedNFT && userClaimedNFT.length>0">Elenco dei tuoi NFT</h2>
							<claimed-nft *ngFor="let nft of userClaimedNFT" [claimedNFT]="nft"></claimed-nft>
							<div  *ngIf="!walletAlreadyRequired && availableNftToClaim">
								<h2>
									Attenzione. Prima di poter riscattare un premio devi creare un wallet.
								</h2>
							</div>
							<div *ngIf="walletAlreadyRequired && availableNftToClaim">
								<p>&nbsp;</p>
								<p>&nbsp;</p>
								<p>&nbsp;</p>
								<h2 style="cursor: pointer;" (click)="claimNow()">Complimenti, hai un nuovo premio da riscattare! Clicca qui per riscattarlo</h2>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
				
</div>

	</form>
</div>
<div class="col-1">&nbsp;</div>
</div>
	</ng-container>
</base-page>