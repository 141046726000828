<base-page>
	<ng-container page-body>
		<div class="row">
			<div class="col-1">&nbsp;</div>
			<div class="col-10">
				<form [formGroup]="formGroup" *ngIf="formGroup">
					<div class="row" style="margin-top: 20px;">
						<div class="col-3 d-flex flex-sm-row flex-column justify-content-left">
							<img *ngIf="profileImage" src="{{profileImage}}" alt="profile-img" class="rounded mr-3"
								height="64" width="64">
							<span>
								<a routerLink="/user-profile">{{firstName}} {{lastName}}</a><br>
								<a (click)="logout()">Logout</a>
							</span>
						</div>
						<div class="col-6 d-flex flex-sm-row flex-column justify-content-center">
							<h1 class="color-primary">{{companyName}}</h1>
						</div>
						<div class="col-3 d-flex flex-sm-row flex-column justify-content-end">
							<div style="height: 100px; width: 200px;">
								<img src="{{logoImage}}" style="max-width: 200px;">
							</div>
						</div>
					</div>
					<div>
						<div class="row">
							<div class="col-md-3 mt-3">
								<ul class="nav flex-column nav-pills" id="myTab" role="tablist">
									<li class="nav-item">
										<a class="nav-link" href="javascript:;"
											[ngClass]="{active: activeTab === 'general'}"
											(click)="setActiveTab('general')" id="general-tab" data-toggle="tab"
											role="tab" aria-controls="general" aria-selected="true">
											<i class="ft-home mr-1 align-middle"></i>
											<span class="align-middle">Azienda</span>
										</a>
									</li>

									<li class="nav-item" *ngIf="showProducts">
										<a class="nav-link" href="javascript:;"
											[ngClass]="{active: activeTab === 'products'}"
											(click)="setActiveTab('products')" id="products-tab" data-toggle="tab"
											role="tab" aria-controls="products" aria-selected="false">
											<i class="ft-database mr-1 align-middle"></i>
											<span class="align-middle">Prodotti</span>
										</a>
									</li>

									<li class="nav-item" *ngIf="enabledCompany && showUserPermission" >
										<a class="nav-link" href="javascript:;"
											[ngClass]="{active: activeTab === 'users'}"
											(click)="setActiveTab('users')" id="users-tab" data-toggle="tab"
											role="tab" aria-controls="users" aria-selected="false">
											<i class="ft-share-2 mr-1 align-middle"></i>
											<span class="align-middle">Utenti abilitati</span>
										</a>
									</li>
								</ul>
							</div>
							<div class="col-md-9">
								<!-- Tab panes -->
								<div class="card">
									<div class="card-content">
										<div class="card-body">
											<div class="tab-content">
												<!-- General Tab -->
												<div class="tab-pane" [ngClass]="{active: activeTab === 'general'}"
													id="general" role="tabpanel" aria-labelledby="general-tab">
													<div class="row">
														<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
															<input-file  notShowAlertOnFileDelete="true"
															formControlName="logo"
															[possibileMediaTypes]="possibileMediaTypes"
															label="Logo aziendale"></input-file>
														</div>
														<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9">
															<div class="row" *ngIf="isAdmin">
																<div class="col-12">
																	<input-string formControlName="companyId" label="Identificativo univoco" [disabled]="true">
																	</input-string>
																</div>
															</div>
															<div class="row">
																<div class="col-12">
																	<input-string formControlName="name" label="Nome">
																	</input-string>
																</div>
															</div>
															<div class="row">
																<div class="col-12">
																	<input-string formControlName="address" label="indirizzo">
																	</input-string>
																</div>
															</div>
															<div class="row">
																<div class="col-4">
																	<input-string formControlName="city" label="Città/comune">
																	</input-string>
																</div>
																<div class="col-4">
																	<input-string formControlName="cap" label="CAP">
																	</input-string>
																</div>
																<div class="col-4">
																	<select-box formControlName="province" label="Provincia" [items]="provinces" [multiple]="false">
																	</select-box>
																</div>
															</div>
		
														</div>
													</div>
													<hr class="mt-1 mt-sm-2">
													
													<div class="row">
														<div class="col-6">
															<input-string formControlName="phoneNumber"
																label="Numero di telefono">
															</input-string>
														</div>
														<div class="col-6">
															<input-string formControlName="email"
																label="indirizzo Email">
															</input-string>
														</div>
													</div>



												

													<div class="row">
														<div class="col-12">
															<input-string formControlName="additionalInfo"
																label="Ulteriori informazioni" rows="5">
															</input-string>
														</div>
													</div>

													<div class="row">
														<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
															<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!baseInfoValid" (click)="savebaseInfo()">Salva</button>
															<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="deleteCompany()">Elimina Azienda</button>
														</div>
														
													</div>
												</div>

												<!-- Address Tab -->
												<div class="tab-pane" [ngClass]="{active: activeTab === 'products'}" id="products" role="tabpanel" aria-labelledby="products-tab">
													<div  *ngFor="let product of products">
														<div class="row">
															<div class="col-12">
																<div class="row">
																	<div class="col col-3">
																		<img src="{{getProductImage(product)}}" style="max-width: 100px;">
																	</div>
																	<div class="col-7">
																		<h4>{{product.name}}</h4>
																		<p *ngFor="let field of product.productFields">
																			<strong>{{field.name}}</strong>: {{field.value}}
																		</p>
																		<!-- <p><a href="https://verifier.posti.world/{{enabledCompany.companyId}}/{{product.id}}/verify" target="_blank">certificato del prodotto</a></p> -->
																	</div>
																	<div class="col-2">
																		<button *ngIf="!product.certified" type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="editProduct(product)" >
																			<i class="{{iconEdit}}" placement="top" [ngbTooltip]="'Modifica ' + product.name"></i>
																		</button>
																		<button *ngIf="product.certified && product.landingPageUrl" type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="showLandingPage(product)" >
																			<i class="{{iconLink}}" placement="top" [ngbTooltip]="'visulizza landing page di ' + product.name"></i>
																		</button>
																		<button *ngIf="product.certified && product.landingPageUrl" type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="showCertPage(product)" >
																			<i class="{{iconCertPage}}" placement="top" [ngbTooltip]="'visulizza certificato di ' + product.name"></i>
																		</button>
																		<button  type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="deleteProduct(product)" >
																			<i class="{{iconDelete}}" placement="top" [ngbTooltip]="'Elimina ' + product.name | translate"></i>
																			
																		</button>
																		<!-- <button *ngIf="!product.certified" type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="editProduct(product)" >
																			<i class="{{iconDelete}}" placement="top" [ngbTooltip]="'Elimina ' + product.name"></i>
																		</button> -->
																		
																		<!-- <button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="editProduct(product)" >Modifica prodotto</button> -->
																	</div>
																</div>
															</div>
														</div>
														<p *ngIf="!product.linkedEntity || product.linkedEntity.length == 0" class="font-medium-3">
															Non hai ancora associato tipi di certificati a '{{product.name}}'.
														</p>
														<p *ngIf="product.linkedEntity?.length > 0" class="font-medium-3">Tipi di certificati associati a <i>{{product.name}}</i></p>
														<div class="row" *ngFor="let entity of product.linkedEntity; let i=index">
															<div class="col-8">
																{{entity.name}}
															</div>
															<div class="col-4">
																
																<button type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="showEntityList(product, entity)" >
																	<i class="{{iconList}}" placement="top" [ngbTooltip]="'Visualizza i certificati per ' + entity.name | translate"></i>
																</button>
																<button  type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="editProduct(product)" >
																	<i class="{{iconAdd}}" placement="top" [ngbTooltip]="'Aggiungi ' + entity.name | translate"></i>
																</button>
																<button  *ngIf="isAdmin" type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="editEntity(product, entity)" >
																	<i class="{{iconEdit}}" placement="top" [ngbTooltip]="'Modifica ' + entity.name | translate"></i>
																</button>
																<button  *ngIf="isAdmin" type="button" class="btn-clear mr-sm-2 mb-1 cursor-pointer" (click)="deleteEntity(product, i)" >
																	<i class="{{iconDelete}}" placement="top" [ngbTooltip]="'Elimina ' + entity.name | translate"></i>
																	
																</button>
															</div>
														</div>
														<div class="row text-right" *ngIf="isAdmin">
															
															<div class="col-12 mt-2">
																<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="addEntity(product)" >Aggiungi nuovo tipo di certificato</button>
															</div>
														</div>
														<hr class="mt-1 mt-sm-2" />
													</div>
													
													<div class="row">
														<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
															<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="addProduct()">Aggiungi nuovo prodotto</button>
														</div>
													</div>
													


												</div>


												<div  *ngIf="enabledCompany && showUserPermission" class="tab-pane" [ngClass]="{active: activeTab === 'users'}" id="users" role="tabpanel" aria-labelledby="users-tab">
													<company-user-permission [company]="enabledCompany">
													</company-user-permission>

												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

				</form>
			</div>
			<div class="col-1">&nbsp;</div>
		</div>
	</ng-container>
</base-page>