<div class="ratio ratio-16x9">
	<div ng2FileDrop class="py-5 mb-3 text-center  drop-zone"
	[ngClass]="{'invalid-drop-zone': invalid, 'text-danger': invalid, 'text-primary': (!invalid), 'nv-file-over': hasBaseDropZoneOver}"
	(fileOver)="fileOverBase($event)"
	[uploader]="uploader"

	style="position:relative; background-repeat: no-repeat; background-size: contain; background-position: center;"
	[style.background-image]="'url(' + ((currentFile)? currentFile.imageUrl: '') + ')'"
	>
		<div *ngIf="!readonly && updateEnabled" class="container-div" (click)="clickOnDropzone($event)">
			<i class="font-large-2 icon-cloud-upload"></i>
			<div>
				<div class="font-small-2">trascina qui un file oppure clicca per selezionarlo</div>
				<input #fileInputElement type="file" ng2FileSelect [uploader]="uploader"  class="custom-file-input">

			</div>
		</div>
		<a *ngIf="mediaUrl" href="{{mediaUrl}}" target="_new"><i  class="open-button font-medium-3 {{iconElementView}}" ></i></a>
		<i *ngIf="!invalid && !readonly" class="show-image-button font-medium-3 {{iconToShow}}" (click)="clickOnshowFile($event)"></i>
		<i *ngIf="!invalid && !readonly" class="show-remove-button font-medium-3 {{iconTrash}}" (click)="removeImage($event)"></i>
		<div *ngIf="item && item.isUploading" class="progress file-progress-bar" style="margin-bottom: 0;">
			<div class="progress-bar" role="progressbar"
				[ngStyle]="{ 'width': item?.progress + '%' }">
				{{item?.progress + '%'}}
			</div>
		</div>
</div>
</div>
<!-- <div class="row">
	<div class="col-md-12 col-lg-6">

		<div ng2FileDrop class="py-5 mb-3 text-center  drop-zone"
			[ngClass]="{'invalid-drop-zone': invalid, 'text-danger': invalid, 'text-primary': (!invalid), 'nv-file-over': hasBaseDropZoneOver}"
			(fileOver)="fileOverBase($event)"
			[uploader]="uploader"

			style="position:relative; background-repeat: no-repeat; background-size: contain; background-position: center;"
			[style.background-image]="'url(' + ((currentFile)? currentFile.imageUrl: '') + ')'"
			>
				<div *ngIf="!readonly && updateEnabled" class="container-div" (click)="clickOnDropzone($event)">
					<i class="font-large-2 icon-cloud-upload"></i>
					<div>
						<div class="font-small-2">trascina qui un file oppure clicca per selezionarlo</div>
						<input #fileInputElement type="file" ng2FileSelect [uploader]="uploader"  class="custom-file-input">
	
					</div>
				</div>
				<a *ngIf="mediaUrl" href="{{mediaUrl}}" target="_new"><i  class="open-button font-medium-3 {{iconElementView}}" ></i></a>
				<i *ngIf="!invalid" class="show-image-button font-medium-3 {{iconToShow}}" (click)="clickOnshowFile($event)"></i>
				<i *ngIf="!invalid" class="show-remove-button font-medium-3 {{iconTrash}}" (click)="removeImage($event)"></i>
				<div *ngIf="item && item.isUploading" class="progress file-progress-bar" style="margin-bottom: 0;">
					<div class="progress-bar" role="progressbar"
						[ngStyle]="{ 'width': item?.progress + '%' }">
						{{item?.progress + '%'}}
					</div>
				</div>
		</div>
		
	</div>
	
</div> -->
