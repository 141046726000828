import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { iconAdd, iconTrash } from "app/common/utils/icons.utils";
import { RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';

import { BlockChainService } from 'app/services/blockchain.service';
import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { BaseComponent } from 'app/common/components/base.component';
import { CompanyService } from 'app/services/company.service';
import { ProductService } from 'app/services/product.service';
import { Company, EntityDefinition, FieldDefinition, FieldType, FieldValidator, GroupDefinition, Product, ValidatorType } from 'app/models/entities.model';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { EntityService } from 'app/services/entity.service';
import { UserProfile } from 'app/models/profile.models';
import { environment } from 'environments/environment';
import moment from 'moment';

@Component({
    selector: 'edit-entity-page',
    templateUrl: './edit-entity.page.html'
})

export class EditEntityPage extends BaseComponent implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconAdd = iconAdd;
	iconDelete = iconTrash;
	editorOptions;
	public entityForm: FormGroup = null;	
	public walletForm: FormGroup = null;


	productDefinition: Product;
	referredEntity: EntityDefinition;
	company: Company;
	userProfile: UserProfile;

	ngOnInit() {

		this.walletForm =this._fb.group({
			secret: [null, CommonValidators.required]
		});

		this.on(this._activatedRoute.paramMap.subscribe(queryParams => {
			let toCall : any = {};
			console.log("queryParam ", queryParams);
			
			if (queryParams.get("id")) {
				toCall["id"] = 	this._entityService.get(queryParams.get("companyId"),  queryParams.get("productId"), queryParams.get("entityType"),queryParams.get("id") );
			}
			toCall["userProfile"] = this._profileService.getLoggedProfile();
			toCall["product"] = this._productService.getByField( queryParams.get("companyId"), "productId", queryParams.get("productId"));
			toCall["company"] = this._companyService.getCompanyByField("companyId", queryParams.get("companyId"));
			console.log("queryParams",queryParams);
			forkJoin(toCall).subscribe((data) => {
				this.company = data["company"];
				this.productDefinition = data["product"];
				this.userProfile = data["userProfile"];
				this.productDefinition.linkedEntity.forEach((entity) => {
					if (entity.entityId == queryParams.get("entityType")) {
						this.referredEntity = entity;
					}
				})

				this.initForm((data["id"])? data["id"]: null);
				if (data["id"]) {
					this.entityForm.patchValue(data["id"]);
				}
				// this.entityForm.patchValue({});
			})
			
			// if (this.currentDomain && domainConfig[queryParams.get("domain")]) {
			// 	this.downloadExcelEnabled = domainConfig[queryParams.get("domain")].downloadExcelEnabled;
			// 	this.deleteEntityEnabled =domainConfig[queryParams.get("domain")].deleteEntityEnabled
			// 	this.createColumms(queryParams.get("domain"));
			// 	this.createActions(queryParams.get("domain"));
			// 	this._titleService.setTitle(domainConfig[queryParams.get("domain")].title)
			// 	let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
			// 	favIcon.href = domainConfig[queryParams.get("domain")].pageIcon;
			// }
		}));

		// this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		// this._activatedRoute.params.subscribe(params => {
		// 	let id = params['id'];
		// 	this.currentEntityClass = params["entityClass"];

		// 	let domain = params["domain"];
		// 	this.currentDomainConfig = domainConfig[domain];
			
		// 	if (id != undefined || id != null) {
		// 		this._restaurantProvider.getRestaurant(domain,id).subscribe((result) => {
		// 			this.initForm(result);					
		// 			this.entityForm.patchValue(this.unmarshalData(result));
		// 		})
		// 	}
		// 	else {
		// 		this.initForm();
		// 	}
		// });
	}

    constructor(
		private _companyService: CompanyService,
		private _entityService: EntityService,
		private _productService: ProductService,
		private _profileService: ProfileService,
		private _activatedRoute: ActivatedRoute,
		private _fb: FormBuilder,
		private _router: Router,
		private _modalService: ModalService)
		
		{
			super();
    }

	goToList = () => {
		let callback = (data) => {
			if (data) {
				this._activatedRoute.queryParams.subscribe(qParam => {
					if (qParam.returnUrl) {
						this._router.navigateByUrl(qParam.returnUrl);
					}
					else {
						this._router.navigate(["/", SubmissionResult.RESULT_OK]);
					}
				});
			}
		}
		this._modalService.showConfirm("Interrompi la compilazione", "Sei sicuro di voler interrompere la compilazione del form?", {
			callback: callback,
			size: "md"
		});
	}

	addField = (group: GroupDefinition, value?: any) => {
		let gDef: any = {};
		group.fields.forEach((field) => {
			let validators = [];
			if (field.validators) {
				field.validators.forEach((validator) => {
					if (validator.validatorType == ValidatorType.REQUIRED) validators.push(CommonValidators.required); 
				})
			}
			gDef[""+field.fieldLabel] = [field.defaultValue, validators];
		})
		let additional =  this._fb.group(gDef);
		if (value) additional.patchValue(value);
		(<FormArray>this.entityForm.get(""+group.groupLabel)).push(additional);
	}
	removeField = (group: GroupDefinition, index: number) => {
		(<FormArray>this.entityForm.get(""+group.groupLabel)).removeAt(index);
	}

	private initForm = (initData?: any) => {
		let entityFormDef: any = {
			id: [null]
		};
		this.referredEntity.groups.forEach((group) => {
			if (group.isArray) {
				let arrDef = this._fb.array([]);
				if (initData && initData[""+group.groupLabel] && initData[""+group.groupLabel].length >0) {
					
					initData[""+group.groupLabel].forEach(element => {
						let gDef: any = {};
						group.fields.forEach((field) => {
							let validators = [];
							if (field.validators) {
								field.validators.forEach((validator) => {
									if (validator.validatorType == ValidatorType.REQUIRED) validators.push(CommonValidators.required); 
								})
							}
							gDef[""+field.fieldLabel] = [(!initData)?field.defaultValue: null, validators];
						})
						let additional =  this._fb.group(gDef);
						arrDef.push(additional);
					});
					
				}
				entityFormDef[""+group.groupLabel] = arrDef;
			}
			else {
				let gDef: any = {};
				group.fields.forEach((field) => {
					let validators = [];
					if (field.validators) {
						field.validators.forEach((validator) => {
							if (validator.validatorType == ValidatorType.REQUIRED) validators.push(CommonValidators.required); 
						})
					}
					gDef[""+field.fieldLabel] = [(!initData)?field.defaultValue: null, validators];
				})
				entityFormDef[""+group.groupLabel] = this._fb.group(gDef);
			}
		})


		

		this.entityForm = this._fb.group(entityFormDef);
		
		
	}

	getFormGroup = (label) => {
		let fg  = this.entityForm.get(label);
		// console.log("fg " + label, fg);
		return fg;
	}

	getBaseCertData = () => {
		let result = [
			{title: "Dati relativi all'azienda", fields: [
				{key: "azienda", value: this.company.name},
				{key: "indirizzo azienda", value: this.company.address + ", " + this.company.cap + " " + this.company.city+ " (" + this.company.province +")"}
			]},
			{
				title: "Dati relativi al prodotto di riferimento",
				fields: [
					{key: "prodotto", value: this.productDefinition.name}
				]
			}
		];
		this.productDefinition.productFields.forEach((field) => {
			result[1].fields.push(
				{key: "Prodotto - " + field.name, value: field.value}
			)
		})

		let data = this.entityForm.value;
		let i = 1;
		for (const group of this.referredEntity.groups)  {
			i++;
			result[i] = {
				title: group.groupLabel,
				fields: []
			}
			if (group.isArray) {
				if (data[group.groupLabel] && data[group.groupLabel].length > 0) {
					(<any[]>data[group.groupLabel]).forEach((element,index) => {
						let values = [];

						for (const field of group.fields) {
							if (field.fieldToCertify &&  element[field.fieldLabel]) {
								if (field.fieldType == FieldType.IMAGE || field.fieldType == FieldType.FILE) {
									values.push(field.fieldLabel + ": " + environment.services.mediaContentsBasePath + "/"+ element[field.fieldLabel]); 
									
								}
								else if (field.fieldType == FieldType.DATE) {
									values.push(field.fieldLabel + ": " + moment( element[field.fieldLabel]).format("DD/MM/YYYY"));
								}
								else if (field.fieldType == FieldType.MULTIPLE_SELECT &&  element[field.fieldLabel] &&  element[field.fieldLabel].join) {
									values.push(field.fieldLabel + ": " +  element[field.fieldLabel]?.join(", "));
								}
								else {
									values.push(field.fieldLabel + ": " + element[field.fieldLabel]);
								}
							}
						}
						result[i].fields.push({
							key: (index+1) + ". " + group.groupLabel,
							value: values.join(" - ")
						})
					});
				}
			}
			else {
				for (const field of group.fields) {
					if (field.fieldToCertify && data[group.groupLabel][field.fieldLabel]) {
						if (field.fieldType == FieldType.IMAGE || field.fieldType == FieldType.FILE) {
							result[i].fields.push({
								key: field.fieldLabel,
								value: environment.services.mediaContentsBasePath + "/"+ data[group.groupLabel][field.fieldLabel]
							})
						}
						else if (field.fieldType == FieldType.DATE) {
							result[i].fields.push({
								key: field.fieldLabel,
								value: moment(data[group.groupLabel][field.fieldLabel]).format("DD/MM/YYYY")
							})

						}
						else if (field.fieldType == FieldType.MULTIPLE_SELECT && data[group.groupLabel][field.fieldLabel] && data[group.groupLabel][field.fieldLabel].join) {
							result[i].fields.push({
								key: field.fieldLabel,
								value: data[group.groupLabel][field.fieldLabel]?.join(", ")
							})
						}
						else {
							result[i].fields.push({
								key: field.fieldLabel,
								value: data[group.groupLabel][field.fieldLabel]
							})
						}
					}
				}
			}
		}
		return result;
	}

	
    public save(certData?: boolean) {
		this._activatedRoute.paramMap.subscribe(queryParams => {
			this._entityService.saveOrUpdate(
				this.entityForm.value,
				queryParams.get("companyId"),  queryParams.get("productId"), queryParams.get("entityType")).subscribe((result) => {

					if (certData) {
						this._entityService.certify(result, this.referredEntity, this.productDefinition, this.company, this.userProfile, this.walletForm.value.secret).then((result) => {
							this._activatedRoute.queryParams.subscribe(qParam => {
								if (qParam.returnUrl) {
									this._router.navigateByUrl(qParam.returnUrl);
								}
								else {
									if (result) {
										this._router.navigate(["/", SubmissionResult.RESULT_OK]);
									}
								}
							});
						})
					}
					else {
						this._activatedRoute.queryParams.subscribe(qParam => {
							if (qParam.returnUrl) {
								this._router.navigateByUrl(qParam.returnUrl);
							}
							else {
								if (result) {
									this._router.navigate(["/", SubmissionResult.RESULT_OK]);
								}
							}
						});
					}
					

							
			})
		})

        // if (this.entityForm.valid) {
        //     this._restaurantProvider.saveOrUpdateRestaurant(this.currentDomainConfig.domainId, this.marshalData(this.entityForm.value))
		// 	.pipe(
		// 		catchError(error => {
		// 			this._modalService.showErrors(["Si è verificato un errore durante il salvataggio dei dati."]);
		// 			return of(null);
		// 		})
		// 	)
		// 	.subscribe(
		// 		(result) => {
		// 			if (certData) {
		// 				this.certifyData(this.formDefinition, result)
		// 					.catch((error) => {
		// 						this._modalService.showErrors(["Si è verificato un errore durante la fase di certificazione dei dati. Si prega di riprovare più tardi o contattare il servizio clienti."]);
		// 					})
		// 					.then((certResult) => {
		// 						this._activatedRoute.queryParams.subscribe(qParam => {
		// 							if (qParam.returnUrl) {
		// 								this._router.navigateByUrl(qParam.returnUrl);
		// 							}
		// 							else {
		// 								if (result) {
		// 									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
		// 								}
		// 								else {
		// 									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
		// 								}
		// 							}
		// 						})
		// 					})
		// 			}
		// 			else {
		// 				this._activatedRoute.queryParams.subscribe(qParam => {
		// 					if (qParam.returnUrl) {
		// 						this._router.navigateByUrl(qParam.returnUrl);
		// 					}
		// 					else {
		// 						if (result) {
		// 							this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
		// 						}
		// 						else {
		// 							this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
		// 						}
		// 					}
		// 				})
		// 			}
		// 		},
		// 		(error) => {
		// 			//console.log("Salvataggio completato correttamente");
		// 			this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
		// 		}
		// 	)
        // }
		// else {
		// 	//console.log("form not valid");
			
		// }
    }

	public get logoImage(): string {
		return environment.domainConfig.companyLogo;

	}
	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.walletForm.valid;
	}
}


