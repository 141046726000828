<!-- <base-page [title]="'Tables' | translate" [description]="'Componenti Tabelle' | translate"> -->
<base-page>
    <ng-container page-body>
        <div class="card">
            <div class="card-header">
                {{productName}}. Elenco di certificati di dipo "<strong>{{entityName}}</strong>"
            </div>
			<div class="card-header">
				<div class="row">
					<div class="col-6">
						<span class="badge badge-success">{{numberOfAvailableItems}}</span> certificati trovati
					</div>
					<div class="col-6 text-right">
						<button type="button" class="btn btn-primary">
							<i class="fas fa-file-excel"></i>&nbsp;Scarica modello Excel
						</button>
						&nbsp;
						<button type="button" class="btn btn-primary">
							<i class="fas fa-upload"></i>&nbsp;Caricamento massivo da excel
						</button>
						&nbsp;
						<!-- <button type="button" (click)="downloadCSV()"  class="btn btn-primary">
							<i class="fas fa-file-csv"></i>&nbsp;scarica CSV
						</button> -->
						&nbsp;
						<!-- <button type="button" (click)="addEntity()"  class="btn btn-primary" *ngIf="!actions()">
							<i class="fas fa-plus"></i>&nbsp;nuovo
						</button>
						<div class="dropdown btn-group mr-1 mb-1" ngbDropdown *ngIf="actions()">
							<button type="button" class="btn btn-primary dropdown-toggle"  [enabled-by-permission]="'contents.write'" ngbDropdownToggle>
								<i class="fas fa-plus"></i> aggiungi
								
							</button>
							<div ngbDropdownMenu class="dropdown-menu" role="menu">
								<a *ngFor="let item of actions()" class="dropdown-item" (click)="addEntity(item.entityType)" >{{item.entityName | translate}}</a>								
							</div>
						</div> -->
						
					</div>
				</div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="row" *ngIf="dataLoaded">
                        <div class="col-12">
                            <list-table #listTable [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions" [filterKeys]="filterKeys" [filterText]="filterValue">
                            </list-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
    </ng-container>
</base-page>