import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbNavModule, NgbPaginationModule, NgbProgressbarModule, NgbToastModule, NgbTooltipModule, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { DirectiveModule } from "../directives/directives.module";
import { InputDateComponent } from "./form/input-date.component";
import { InputDecimalComponent } from "./form/input-decimal.component";
import { InputIntComponent } from "./form/input-int.component";
import { InputSlideComponent } from "./form/input-slide.component";
import { InputStringComponent } from "./form/input-string.component";
import { SelectBoxComponent } from "./form/select-box.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { InputAutocompleteComponent } from "./form/input-autocomplete.component";
import { QuillModule } from 'ngx-quill';
import { InputHtmlComponent } from "./form/input-html.component";
import { DataTableComponent } from "./table/data-table.component";
import { DataTableUserAvatarCellTemplateComponent, DataTableImageCellTemplateComponent, DataTableStringCellTemplateComponent, DataTableDateCellTemplateComponent, DataTableEnumCellTemplateComponent, DataTableBooleanCellTemplateComponent, DataTableDateTimeCellTemplateComponent, DataTableTimeCellTemplateComponent, DataTableIntCellTemplateComponent, DataTableNumberCellTemplateComponent, DataTableCurrencyCellTemplateComponent, DataTableClickCellTemplateComponent, DataTableArrayCellTemplateComponent, DataTableIconImageCellTemplateComponent } from "./table/data-table-cell-template.component";
import { DataTableCellTemplateDirective } from "./table/data-table-cell-template.directive";
import { ListTableComponent } from "./table/list-table.component";
import { ModalComponent } from "./modals/modal.component";
import { ConfirmModalComponent } from "./modals/confirm-modal.component";
import { ErrorsModalComponent } from "./modals/errors-modal.component";
import { ToastsComponent } from "./toast/toasts.component";
import { InputTagsComponent } from "./form/input-tags.component";
import { TagInputModule } from 'ngx-chips';
import { SpinnerComponent } from "./spinner/spinner.component";
import { BasePageComponent } from "./pages/base-page.component";
import { LocalizedInputContainer } from "./form/localized-input-container.component";
import { FileUploadModule } from 'ng2-file-upload';


import { ListTableFilter } from "./table/list-table-filter.component";
import { InputFileComponent } from "./form/input-file.component";
import { FileUploadComponent } from "./files/FIleUpload";
import { InputJSONComponent } from "./form/input-json.component";
import { NgJsonEditorModule } from "ang-jsoneditor";
import { SecuredImage } from "./files/secured-image.component";

import { QRCodeModule } from 'angularx-qrcode';
import { PipeModule } from "app/pipes/pipe.module";
import { ClaimedNFTComponent } from "./claimed-nft/claimed-nft.component";
import { SimpleQRCodeComponent } from "./modals/simple-qrcode.component";
import { UpdatePasswordComponent } from "./modals/update-password-modal.component";
import { InputPasswordComponent } from "./form/input-password.component";

@NgModule({
    declarations: [
        InputStringComponent,
        InputDateComponent,
        InputDecimalComponent,
        InputIntComponent,
        InputSlideComponent,
        SelectBoxComponent,
        DataTableComponent,
        ListTableComponent,
        InputAutocompleteComponent,
        InputHtmlComponent,
        InputTagsComponent,
		InputPasswordComponent,
        DataTableCellTemplateDirective,
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        ModalComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
        ToastsComponent,
        SpinnerComponent,
        BasePageComponent,
        LocalizedInputContainer,
		ListTableFilter,
		InputFileComponent,
		FileUploadComponent,
		InputJSONComponent,
		SecuredImage,
		ClaimedNFTComponent,
		SimpleQRCodeComponent,
		UpdatePasswordComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        DirectiveModule,
        NgSelectModule,
        NgbTypeaheadModule,
        NgbPaginationModule,
        NgbDropdownModule,
        NgbToastModule,
        TagInputModule,
        QuillModule.forRoot(),
        NgbNavModule,
        NgbDropdownModule,
		FileUploadModule,
		NgbProgressbarModule,
		NgbTooltipModule,
		NgbModule,
		NgJsonEditorModule,
		QRCodeModule,
		PipeModule
    ],
    providers: [
    ],
    exports: [
        InputStringComponent,
        InputDateComponent,
        InputDecimalComponent,
        InputIntComponent,
        InputSlideComponent,
        FormsModule,
        ReactiveFormsModule,
        SelectBoxComponent,
        InputAutocompleteComponent,
        InputHtmlComponent,
        DataTableComponent,
        ListTableComponent,
        DataTableCellTemplateDirective,
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        NgbDropdownModule,
        ModalComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
        ToastsComponent,
        InputTagsComponent,
		InputPasswordComponent,
        SpinnerComponent,
        BasePageComponent,
        TranslateModule,
        LocalizedInputContainer,
		ListTableFilter,
		InputFileComponent,
		FileUploadComponent,
		InputJSONComponent,
		SecuredImage,
		ClaimedNFTComponent,
		SimpleQRCodeComponent,
		UpdatePasswordComponent
    ],
    entryComponents: [
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
		SecuredImage
    ],
})
export class ComponentsModule { }
