import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { of, timer } from 'rxjs';

import { iconAdd, iconCancel, iconSave, iconTrash } from "app/common/utils/icons.utils";

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { environment } from 'environments/environment';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { BlockChainService } from 'app/services/blockchain.service';
import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { EntityCertificationBaseFormPage } from 'app/pages/forms/EntityCertificationBaseFormPage';
import { FORM_SUBMISSION_RESULT } from 'app/pages/forms/routing-constants';
import { Company, EntityDefinition, FieldDefinition, FieldType, FieldValidator, Product, ProductFieldDef, templateLotto, ValidatorType } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'app/services/product.service';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { URLUtils } from 'app/services/url-utils';

import { v4 as uuidv4 } from 'uuid';
import { EntityService } from 'app/services/entity.service';
import { ValidatorUtils } from 'app/common/components/form/validators/validator-utils';
import { NumberValidators } from 'app/common/components/form/validators/number.validator';

@Component({
    selector: 'edit-base-product-modal',
    templateUrl: './edit-base-product-modal.page.html'
})
export class EditBaseProductModalForm implements OnInit {
	// possibileMediaTypes = [];
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconCancel = iconCancel;
	iconSave = iconSave;
	iconDelete = iconTrash;
	public currentEntityDef: any = {};
	public entityForm: FormGroup = null;	
	public additionalFields: FormArray = null;
	public walletForm: FormGroup = null;
	public linkedNFT: FormGroup = null;
	data: {
		product: Product,
		company: Company,
		userProfile: UserProfile
	};

	public get productDataReadonly() {
		if (this.data && this.data.product && this.data.product.certified) return true;
		return false;
	}

	ngOnInit() {
		let product;
		if (this.data && this.data.product) {
			product = this.data.product;
			
		}
		
		this.initForm(product);
		if (product)
			this.entityForm.patchValue(product);
	}
	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}
    constructor(
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder,
		private _productService: ProductService,
		private _modalService: ModalService,
		private _toastService: ToastService,
		private _userProfileService: ProfileService,
		private _entityService: EntityService
		) {
			this.walletForm =this._fb.group({
				secret: [null]
			});
    }

	addField = (value?: ProductFieldDef) => {
		let additional = this._fb.group({
			name: [null, CommonValidators.required],
			value: [null, CommonValidators.required],
			canBeDelete: [true]
		})
		if (value) additional.patchValue(value);
		this.additionalFields.push(additional);
	}
	removeField = (index: number) => {
		this.additionalFields.removeAt(index);
	}

	public get title() {
		return  "Prodotto " + (this.entityForm)?  this.entityForm.value.name: "";
	}
	private initForm = (initData?: any) => {
		this.startUpdateProgress();
		this.additionalFields = this._fb.array([]);
		if (initData && initData.productFields) {
			initData.productFields.forEach(element => {
				this.addField();
			});
		}
		else {
			this.addField({
				name: "produttore",
				value: this.data.company.name,
				canBeDelete: false
			});
			this.addField({
				name: "provenienza",
				value: this.data.company.city + " (" + this.data.company.province + ")",
				canBeDelete: false
			});
			
		}
		let patter = /^[a-z0-9.,& \-]+$/i;
		let entityFormDef: any = {
			id: [null],
			productId: [null, CommonValidators.required],
			name:  [null, [
				CommonValidators.required,
				CommonValidators.patternMatchingValidator(patter,"Il nome deve essere composto solo di lettere numeri e simboli - . , &"),
				CommonValidators.noWhiteSpaceAtStardAndEnd("non deve contenere spazi all'inizio ed alla fine del nome")
			]],
			image: [null],
			image_3d: [null],
			productFields: this.additionalFields,
			// landingPageUrl: [(initData)?null:URLUtils.getDefaultProductLandingPagePatternURL(), CommonValidators.websiteFormatValidator],
			// certVerifyerUrl: [(initData)?null:URLUtils.getDefaultCertPagePatternURL(), CommonValidators.websiteFormatValidator],
			landingPageUrl: [(initData)?null:URLUtils.getDefaultProductLandingPagePatternURL()],
			certVerifyerUrl: [(initData)?null:URLUtils.getDefaultCertPagePatternURL()],
			linkedEntity: [null]
		};

		this.linkedNFT = this._fb.group({
			numbers: [0, NumberValidators.gtOrEqThan(0)],
			descrizione: []
		})
		this.entityForm = this._fb.group(entityFormDef);
		if (!initData) {
			this.entityForm.get("name").valueChanges.subscribe((name) => {
				if (name)
				this.entityForm.get("productId").patchValue(
					(""+name).trim().toLowerCase()
					.replaceAll(" ", "")
					.replaceAll(".", "")
					.replaceAll(",", "")
					.replaceAll("-", "")
					.replaceAll("&", "")
					);
			})	
		}
		this.entityForm.valueChanges.subscribe((value) => {
			console.log(value);
			console.log("valid: " + this.entityForm.valid)
			
		})

		this.entityFields = this._fb.array([]);
		if (this.data.product && this.data.product.linkedEntity && this.data.product.linkedEntity.length>0) {
			let index = this.data.product.linkedEntity.findIndex((entity) => {
				return entity.entityId && entity.entityId.toLowerCase() == "lotto";
			})

			if (index >= 0) {
				let entityDef: EntityDefinition = this.data.product.linkedEntity[index];
				entityDef.groups.forEach((group) => {
					group.fields.forEach((field) => {
						this.addEntityField({name: field.fieldLabel, value: field.defaultValue, canBeDelete: true});
					})
				})
			}
			else {
				this.addEntityField({name: "data produzione", value: "", canBeDelete: true});
				this.addEntityField({name: "data confezionamento", value: "", canBeDelete: true});
			}
		}
		else {
			this.addEntityField({name: "data produzione", value: "", canBeDelete: true});
			this.addEntityField({name: "data confezionamento", value: "", canBeDelete: true});
		}

	}


    public save(certData?: boolean) {
        if (this.entityForm.valid) {
			let toSave: Product = new Product();
			if (this.data.product) {
				Object.assign(toSave, this.data.product);
				Object.assign(toSave, this.entityForm.value);
				if (this.data.product.linkedEntity && this.data.product.linkedEntity.length>0) {
					let index = this.data.product.linkedEntity.findIndex((entity) => {
						return entity.entityId && entity.entityId.toLowerCase() == "lotto";
					})
					if (index >= 0) {
						this.data.product.linkedEntity[index] = this.makeLinkedEntity(this.data.product.linkedEntity[index]);
						toSave.linkedEntity[index] = this.data.product.linkedEntity[index];
					}
					else {
						this.data.product.linkedEntity.push(this.makeLinkedEntity());
						toSave.linkedEntity.push(this.makeLinkedEntity());
					}
				}
				else {
					this.data.product.linkedEntity = [];
					toSave.linkedEntity = [];
					this.data.product.linkedEntity.push(this.makeLinkedEntity());
					toSave.linkedEntity.push(this.makeLinkedEntity());
				}
			}
			else {
				Object.assign(toSave, this.entityForm.value);
				if (!toSave.linkedEntity) toSave.linkedEntity = [];
				toSave.linkedEntity.push(this.makeLinkedEntity());
			}
			
            this._productService.saveOrUpdate(this.data.company.companyId, toSave)
			.pipe(
				catchError(error => {
					this._toastService.showDanger("Si è verificato un errore durante il salvataggio dei dati.");
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					this.data.product = result;
					if (certData) {
						this._productService.certify(result, this.data.company, this.data.userProfile, this.walletForm.value.secret)
							.catch((error) => {
								this._toastService.showDanger("Si è verificato un errore durante la fase di certificazione dei dati. Si prega di riprovare più tardi o contattare il servizio clienti.");
							})
							.then((certResult) => {
								this._toastService.showSuccess("I dati del prodotto sono stati salvati ed è stata richiesta correttamente la certificazione.");
								this.closeModal(result, certData);
							})
					}
					else {
						this._toastService.showSuccess("I dati del prodotto sono stati salvati.");
						this.closeModal(result, certData);
					}
					
				},
				(error) => {
					this._modalService.showErrors(["Si è verificato un errore durante la fase di salvataggio dei dati. Si prega di riprovare più tardi o contattare il servizio clienti."]);

				}
			)
        }
		else {
		}
    }

	progress = 0;

	startUpdateProgress() {
		timer(1000, 1000).subscribe(res => {
			this.progress = Number.parseInt(""+((this.progress + 5) % 100));
		})
	}

	private closeModal(data, certData?) {
		
		if (data) {
			this.saveLinkedEntity(certData);
		}
		this.activeModal.close(data);
	}

	public get logoImage(): string {
		return environment.domainConfig.companyLogo;
	}

	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.entityFields && this.entityFields.valid;
	}

	public get canSaveAndCertify(): boolean {
		return this.entityForm.valid && this.walletForm.value.secret && this.entityFields && this.entityFields.valid;
	}

	public close = (): void => {
		this.closeModal(false);
    }


	/**
	 * metodi specifici per l'entità lotto di default
	 */
	
	entityFields: FormArray;

	addEntityField = (value?: ProductFieldDef) => {
		let patter = /^[a-z0-9 \-]+$/i;
		let additional = this._fb.group({
			name: [null, [CommonValidators.required,
				CommonValidators.patternMatchingValidator(patter,"Il campo deve essere composto solo di lettere, numeri, spazi e simbolo -"),
				CommonValidators.noWhiteSpaceAtStardAndEnd("Il campo non deve contenere spazi all'inizio ed alla fine")]
			],
			value: [null, CommonValidators.required],
			canBeDelete: [true]
		})
		if (value) additional.patchValue(value);
		this.entityFields.push(additional);
	}
	removeEntityField = (index: number) => {
		this.entityFields.removeAt(index);
	}


	makeLinkedEntity(initData?) : EntityDefinition {
		let template: EntityDefinition = {
			entityId: "lotto",
			name: "Lotto",
			certVerifyerUrl: (initData)?initData.certVerifyerUrl: environment.domainConfig.defaultCertPagePatternURL,
			groups: [
				{
					isArray: false,
					fields: [],
					groupId: "75d655a9-dccb-43be-b266-a091031bb5ce",
					groupLabel: "Informazioni specifiche per il Lotto"
				}
			],
			landingPageUrl: (initData)?initData.landingPageUrl: "",
			hasLinkedNFT: true
		}
		if (this.entityFields && this.entityFields.value) {
			this.entityFields.value.forEach(element => {
				let field: FieldDefinition = {
					fieldId: uuidv4(),
					fieldLabel: element.name,
					fieldType: FieldType.STRING,
					possibleValues: [],
					validators: [{validatorType: ValidatorType.REQUIRED,constraint: []}],
					hidden: false,
					fieldToCertify: true
				}

				template.groups[0].fields.push(field);
			});
		}

		return template;
	}


	templateNFT: EntityDefinition = {
		entityId: "lotto.nft",
		name: "NFT",
		certVerifyerUrl: environment.domainConfig.defaultCertPagePatternURL,
		groups: [
			{
				isArray: false,
				fields: [
					{
						fieldId: "descrizione",
						fieldLabel: "descrizione",
						fieldToCertify: true,
						fieldType: FieldType.DESCRIPTION,
						hidden: false,
						validators: []
					}
				],
				groupId: "nft_gid",
				groupLabel: "Informazioni specifiche del NFT"
			}
		],
		hasLinkedNFT: false
	}

	showProgress: boolean = false;
	private saveLinkedEntity(certData: boolean) {
		
		let entityDefinition: EntityDefinition = this.makeLinkedEntity();
		let value: any = {};
		value["Informazioni specifiche per il Lotto"] = {};
		this.entityFields.value.forEach((field) => {
			value["Informazioni specifiche per il Lotto"][field.name] = field.value;
		})
		this._entityService.saveOrUpdate(
			value,
			this.data.company.companyId,
			this.data.product.productId,
			entityDefinition.entityId).subscribe((_result) => {
				if (certData) {
					this._entityService.certify(_result, entityDefinition, this.data.product, this.data.company, this.data.userProfile, this.walletForm.value.secret).then((__result) => {
						
						let linkedNft = this.linkedNFT.value;
						if (linkedNft.numbers > 0) {
							let nft: any = {
								refEntityId:_result.id,
								"Informazioni specifiche del NFT": {descrizione: linkedNft.descrizione}
							}
							this.templateNFT.groups.push(entityDefinition.groups[0]);

							for (let i=0; i < linkedNft.numbers; i++) {
								console.log("Saving ", nft);
								
								this._entityService.saveOrUpdate(
									
									nft, this.data.company.companyId, this.data.product.productId, entityDefinition.entityId+".nft").subscribe((r) => {
										if (certData) {
											r["Informazioni specifiche per il Lotto"] = value["Informazioni specifiche per il Lotto"];
											this._entityService.certify(r,this.templateNFT,this.data.product, this.data.company, this.data.userProfile, this.walletForm.value.secret).then((nftRes) => {});
										}
									})
							}
						}
					})
				}							
			})
		}

}


