<modal title="{{title}}" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div class="row formContent" *ngIf="dataLoaded">
			<div class="col-12">
				<form [formGroup]="entityForm" *ngIf="entityForm">
					
					<div class="row">
						<div class="col-12">
							<input-string formControlName="name" label="Nome del tipo di informazione (es. Lotto)*"></input-string>
						</div>
					</div>
					<div class="row" *ngIf="isAdmin">
						<div class="col-12">
							<input-string formControlName="certVerifyerUrl" label="Url alla pagina di verifica certificato"></input-string>
						</div>
					</div>
					<div class="row" *ngIf="isAdmin">
						<div class="col-12">
							<input-string formControlName="landingPageUrl"  label="Url alla landing page"></input-string>
						</div>
					</div>
					
					<div >
						<h3>Sezione di dati</h3>
					</div>
					<div  *ngFor="let group of groups; let i=index" >
						<group-definition [group]="group" (requestDeleteGroup)="removeGroup(i)" (groupValid)="validateGroup(i, $event)"></group-definition>
					</div>
					
					
					<div class="row text-right mt-4" >
						<div class="col-12">
							<button type="button" class="btn btn-primary" (click)="addGroup()">Aggiungi nuova sezione dati</button>
						</div>
					</div>
				</form>
				
				<p>&nbsp;</p>
				

				
				<div *ngIf="!entityForm.valid" class="subtitle-form">
					<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
				</div>
			</div>
		</div>
	</div>
	
    <div class="" footer>
		<div class="row text-right"  *ngIf="dataLoaded">
			<div class="col-12">
				
				<button type="button" (click)="close()" class="btn btn-outline-secondary mr-sm-2 mb-1"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
				<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!canSaveForm" (click)="save(false)" ><i class="{{iconSave}}"></i> SALVA</button>
				
			</div>
		</div>
									

    </div>
</modal>

