import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { ListTableManager } from 'app/common/components/table/types';
import { ModalOptions, SelectListitem } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { ToastService } from 'app/common/services/toasts.service';
import { provinces } from 'app/common/utils/city.utils';
import { iconAdd, iconCertPage, iconEdit, iconElementView, iconLink, iconTrash } from 'app/common/utils/icons.utils';
import { Company, EntityDefinition, Product } from 'app/models/entities.model';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ACR, ACRService } from 'app/services/acl.service';
import { AuthService } from 'app/services/auth.service';
import { CompanyService } from 'app/services/company.service';
import { ProductService } from 'app/services/product.service';
import { ProfileService } from 'app/services/profile.service';
import { URLUtils } from 'app/services/url-utils';
import { environment } from 'environments/environment';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EditEntityModalForm } from '../entity-editor/edit-entity-modal.page';
import { EditBaseProductModalForm } from '../product-editor/edit-base-product-modal.page';

@Component({
	selector: 'company-profile-page',
	templateUrl: './company-profile.page.html'
})

export class CompanyProfilePage implements OnInit {
	pageLoaded = false;
	userProfile: UserProfile;
	enabledCompany: Company;
	formGroup: FormGroup;
	products: Product[] = [];
	iconDelete = iconTrash;
	iconEdit = iconEdit;
	iconList = iconElementView;
	iconLink = iconLink;
	iconCertPage = iconCertPage;
	iconAdd = iconAdd;
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	activeTab = "general";
	provinces: SelectListitem[] = provinces.map((province) => {return {id:province.nome, label: province.nome}});
	//product list data
	public dataTableManager: ListTableManager<Product>[] = [];

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router, 
		private _userProfileService: ProfileService,
		private _fb: FormBuilder,
		private _authService: AuthService,
		private _companyService: CompanyService,
		private _acrService: ACRService,
		private _modalService: ModalService,
		private _productService: ProductService,
		private _toastService: ToastService,
		) {
	}

	private initDashboard = (profile: UserProfile, acrs: ACR[]) => {
	}
	
	initProduct = () => {
		this._productService.getAll(this.enabledCompany.companyId).subscribe((products) => {
			this.products = products;
		})
	}
	ngOnInit() {
		this._userProfileService.getLoggedProfile().subscribe((result) => {
			this.userProfile = result;

			this._activatedRoute.paramMap.subscribe(qp => {
				if (qp && qp.get("companyId")) {

					this._companyService.getCompanyByField("companyId", qp.get("companyId")).subscribe((company) => {
						if (company) {
							this.enabledCompany = company;
							this.initForm();
							this.formGroup.patchValue(this.enabledCompany);
							this.initProduct();
						}
						else {
							this.initForm();
						}
						this.pageLoaded = true;
					},
					(error) => {
						this.initForm();
						this.pageLoaded = true;
					})
				}
				else {
					this.initForm();
					this.pageLoaded = true;
				}
			})

			
		})
	}

	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}

	public goToEnabledDashboard = ($event: MouseEvent, navigationPath): void => {
		$event.preventDefault();
		$event.stopPropagation();

		this._activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			queryParams['returnUrl'] = "/user-profile"
			this._router.navigate(navigationPath, { queryParams: queryParams });
		})
	}

	
	public get profileImage() {
		if (this.enabledCompany && this.enabledCompany.logo) return environment.services.mediaContentsBasePath + "/" + this.enabledCompany.logo;
		if (this.userProfile && this.userProfile.avatarId)
			return environment.services.mediaContentsBasePath + "/" + this.userProfile.avatarId;
		return "assets/img/icons/face-icon-0.jpg.png"
	}

	public get firstName() {
		if (this.userProfile && this.userProfile.firstName)
			return this.userProfile.firstName;
	}
	public get lastName() {
		if (this.userProfile && this.userProfile.lastName)
			return this.userProfile.lastName;
	}

	

	private initForm = (): void => {
		let patter = /^[a-z0-9.,& \-]+$/i;
		
		this.formGroup = this._fb.group({
			id: [null],
			companyId: [null, Validators.required],
			logo:[null],
			name:  [null, [
				Validators.required,
				CommonValidators.patternMatchingValidator(patter,"Il nome deve essere composto solo di lettere numeri e simboli - . , &"),
				CommonValidators.noWhiteSpaceAtStardAndEnd("non deve contenere spazi all'inizio ed alla fine del nome")
			]],
			address:  [null, Validators.required],
			cap: [null, Validators.required],
			city: [null, Validators.required],
			province:  [null, Validators.required],
			coordinates: [null],
			email: [null, [CommonValidators.emailFormatValidator]],
			phoneNumber: [null],
			additionalInfo: [null],
			lastUpdateDate: [null]
		});

		if (!this.enabledCompany) {
			this.formGroup.get("name").valueChanges.subscribe((name) => {
				let d = new Date();
				
				if (name)
					this.formGroup.get("companyId").patchValue(
						(""+name).trim().toLowerCase()
						.replaceAll(" ", "")
						.replaceAll(".", "")
						.replaceAll(",", "")
						.replaceAll("-", "")
						.replaceAll("&", "")
						+ "_" + d.getTime()
						);
				console.log("company id: " + this.formGroup.get("companyId").value);
				
			})	
		}
	}

	setActiveTab(tab) {
		this.activeTab = tab;
	}


	onSaveAccountInfo = () => {

	}

	get baseInfoValid(): boolean {
		return this.formGroup.valid;
	}
	get showUserPermission(): boolean {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}
	savebaseInfo() {
		if (this.baseInfoValid) {
			if (this.enabledCompany) {
				this._companyService.saveOrUpdate(this.formGroup.value).subscribe((result) => {
					this._toastService.showSuccess("Salvataggio avvenuto con successo");
					this.enabledCompany = result;
					this.formGroup.patchValue(this.enabledCompany);
					this.initProduct();

				})
			}
			else {
				this._companyService.saveOrUpdate(this.formGroup.value).subscribe((result) => {
					this._toastService.showSuccess("Azienda creata con successo");
					this.enabledCompany = result;
					this.formGroup.patchValue(this.enabledCompany);
					this._acrService.linkCompanyToUser(this.userProfile, this.enabledCompany, "ADMIN").subscribe((r) => {
					})
				})
			}
		
		}
	}

	public get showProducts() {
		return this.enabledCompany;
	}
	logout = () => {
		this._authService.logout();
	}

	addProduct = () => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					this.initProduct();
				}
			}
		}
		let data = {
			company: this.enabledCompany,
			userProfile: this.userProfile
		}
		this._modalService.show(EditBaseProductModalForm, data, modalOption);
	}

	getProductImage = (product: Product) => {
		if (product && product.image) return environment.services.mediaContentsBasePath + "/" + product.image;
		return "assets/img/icons/descriptive-content.png"
	}
	editProduct = (product: Product) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					this.initProduct();
				}
			}
		}
		let data = {
			product: product,
			company: this.enabledCompany,
			userProfile: this.userProfile
		}
		this._modalService.show(EditBaseProductModalForm, data, modalOption);
	}

	

	public get companyName() {
		if (this.enabledCompany && this.enabledCompany.name) return this.enabledCompany.name;
		return "definisci la tua azieda"
	}

	addEntity = (product: Product) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				
			}
		}
		let data = {
			company: this.enabledCompany,
			userProfile: this.userProfile,
			entity: null,
			product: product
		}
		this._modalService.show(EditEntityModalForm, data, modalOption);
	}

	showEntityList = (product: Product, linkedEntity: EntityDefinition) => {
		const queryParams = {};
		// queryParams['returnUrl'] = this.getReturnUrl();
		this._router.navigate(["/entities-list", this.enabledCompany.companyId, product.productId, linkedEntity.entityId], { queryParams: queryParams });
	}

	editEntity = (product: Product, linkedEntity: EntityDefinition) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				
			}
		}
		let data = {
			company: this.enabledCompany,
			userProfile: this.userProfile,
			entity: linkedEntity,
			product: product
		}
		this._modalService.show(EditEntityModalForm, data, modalOption);
	}

	deleteProduct = (product: Product) => {
		this._productService.delete(this.enabledCompany.companyId, product.id).subscribe((result) => {
			this.ngOnInit();
		})
	};
	deleteEntity = (product: Product, entityIndex) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (res) => {
				if (res) {
					product.linkedEntity.splice(entityIndex,1);
		
					// this._productService.saveOrUpdate(this.enabledCompany.companyId, product).subscribe((result) => {})
			
					this._productService.saveOrUpdate(this.enabledCompany.companyId, product)
						.pipe(
							catchError(error => {
								this._toastService.showDanger("Si è verificato un errore durante il salvataggio dei dati.");
								return of(null);
							})
						)
						.subscribe(
							(result) => {
								if (result) this._toastService.showSuccess("Salvataggio avvenuto con successo");
							},
							(error) => {
								this._toastService.showDanger("Si è verificato un errore dusante il lalvataggio dei dati");
							}
						)
				}
			}
		}
		this._modalService.showConfirm("Elimina tipo di certificato", "Sei sicuro che vuoi eliminare il tipo di certificato '"+product.linkedEntity[entityIndex].name+"' per "+product.name+"? La cancellazione è irreversibile e non avrai più a disposizione i dati.", modalOption);
	}

	showCertPage = (product: Product) => {
		let url = URLUtils.getCertPageURL(this.enabledCompany, product);
		window.open(url, "_blank");
	}
	showLandingPage = (product: Product) => {
		let url = URLUtils.getProductLandingPageURL(this.enabledCompany, product);
		window.open(url, "_blank");
	}

	deleteCompany = () => {
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			callback: (res) => {
				if (res) {
					// this._companyService.delete((this.enabledCompany.id)).subscribe((result) => {
						
					// })
		
					this._acrService.getCompanyACR(this.enabledCompany.companyId).subscribe((acrs: ACR[]) => {
						if (acrs) {
							let toCall = [];
							acrs.forEach((acr) => {
								if (acr.entityId == this.enabledCompany.id) {
									console.log("DELETE ACR " + acr.entityId);
									toCall.push( this._acrService.deleteCompanyACR(acr));
								}

							})
							toCall.push(this._companyService.delete((this.enabledCompany.id)));
							forkJoin(toCall).subscribe((result) => {
								if (result) {
									this._toastService.showSuccess("Eliminazione avvenuta con successo");
									const queryParams = {};
									this._router.navigate(["/user-profile"], { queryParams: queryParams });
								}
							}
							, (error) => {
								this._toastService.showDanger("Si è verificato un errore durante l'eliminazione dell'azienda.");
							}
							)
							
						}
						
					}, (error) => {
						this._toastService.showDanger("Si è verificato un errore durante l'eliminazione dell'azienda.");
					}
					
					)
					
					
					
				}
			}
		}
		this._modalService.showConfirm(
			"Elimina la tua azienda", "Sei sicuro di voler eliminare la tua azienda? La cancellazione è irreversibile e non avrai più a disposizione i dati.", modalOption);

	}

	public get logoImage(): string {
		return environment.domainConfig.companyLogo;
	}

}