import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { UserProfileModule } from '../user-profile/user-profile.module';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CompanyModule } from '../company/company.module';
import { EntityListPage } from './entities-list/entity-list.page';
import { EditEntityPage } from './edit-entity/edit-entity.page';
import { EntityField } from './edit-entity/entity-field.component';
import { ModalEntityList } from './modal-entities-list/entities-list.modal';


export const EntityManagementRoutes: Routes = [
    { path: 'entities-list/:companyId/:productId/:entityType', component: EntityListPage, canActivate: []},
    { path: 'entities-list/:companyId/:productId/:entityType/edit', component: EditEntityPage, canActivate: []},
    { path: 'entities-list/:companyId/:productId/:entityType/edit/:id', component: EditEntityPage, canActivate: []},
    // { path: 'entity/:companyId/:productId/:entityType', component: null, canActivate: []},
    // { path: 'entity/:companyId/:productId/:entityType/:entityId', component: null, canActivate: []}
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(EntityManagementRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule,
		UserProfileModule,
		NgbModule,
		CompanyModule
    ],
    exports: [
		EntityField,
		ModalEntityList
	],
    declarations: [
		EntityListPage,
		EditEntityPage,
		EntityField,
		ModalEntityList
    ],
    providers: [
		
	],
	entryComponents: [
		EntityField,
		ModalEntityList
	]
})
export class EntityManagementModule { }
