<modal title="{{title | translate}}" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<form [formGroup]="formGroup" *ngIf="formGroup">
			<!-- <div class="row">
				<div class="col-12">
					<input-string formControlName="currentPassword" label="Password attuale">
					</input-string>
				</div>
			</div> -->
			<div class="row">
				<div class="col-12">
					<input-password formControlName="value" label="Nuova Password">
					</input-password>
				</div>
			</div>
		</form>
		
	</div>
	
    <div class="text-right" footer>
		<button type="button" (click)="close()" class="btn btn-outline-secondary mr-sm-2 mb-1"><i class="{{iconCancel}}"></i> {{'common.close' | translate}}</button>
		<button type="button" (click)="update()" [disabled]="!validForm" class="btn btn-outline-secondary mr-sm-2 mb-1"><i class="{{iconSave}}"></i> {{'common.save' | translate}}</button>
    </div>
</modal>