import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ChatBotPage } from './chat-bot-page/chat-bot.page';


export const ChatRoutes: Routes = [
    { path: 'chat', component: ChatBotPage, canActivate: []}
    
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(ChatRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule,
		NgbModule
    ],
    exports: [
		
	],
    declarations: [
		ChatBotPage
    ],
    providers: [
		
	],
	entryComponents: [

	]
})
export class ChatBotModule { }
