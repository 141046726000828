import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ModalService } from 'app/common/services/modal.service';
import { iconDownload, iconEdit, iconSave, iconTrash } from 'app/common/utils/icons.utils';
import { FileProvider, MediaFileDescription } from 'app/services/FileProvider.service';
import { environment } from 'environments/environment';
import { FileItem, FileUploader } from 'ng2-file-upload';


@Component({
	selector: 'base-view-file-component',
	templateUrl: './upload.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	styleUrls: ['./upload.component.scss']
})
export class FileUploadComponent implements OnInit {

	uploader: FileUploader;
	hasBaseDropZoneOver = false;
	hasAnotherDropZoneOver = false;
	updateEnabled: boolean = false;
	iconEdit = iconEdit;
	iconSave = iconSave;
	iconTrash = iconTrash;
	iconElementView = iconDownload;
	@Input() mediaId: any;
	@Input() invalid: boolean;
	@Input() readonly: boolean = false;
	@Input() possibileMediaTypes: any[] = [];
	@Input() notShowAlertOnFileDelete: boolean = false;
	@Output() onMediaUpdate: EventEmitter<any> = new EventEmitter();
	@ViewChild('fileInputElement') fileInputElement: ElementRef;
	constructor(private _fileProvider: FileProvider, private _modalService: ModalService) {
		
	}
	get mediaUrl() {
		if (this.mediaId) {
			return environment.services.mediaContentsBasePath + "/" + this.mediaId;
		}
	}
	initFileUploader() {
		if (!this.uploader) {
			this.uploader = new FileUploader({
				itemAlias: "file",
				isHTML5: true,
			});
		}
		this.uploader.options.url= environment.services.mediaContentsBasePath;
		this.uploader.options.method=  "POST";
	}
	item;
	currentFile: MediaFileDescription;
	ngOnInit() {
		this.initFileUploader();		
		if (this.mediaId) {
			this._fileProvider.getFileInfo(this.mediaId).subscribe(result => {
				this.currentFile = result;
			});
		}
		else {
			this.updateEnabled = true;
		}

		this.uploader.onAfterAddingFile = (item: FileItem) => {
			if (this.updateEnabled) {
				if (this.possibileMediaTypes.length==0 || (this.possibileMediaTypes.length > 0 && this.possibileMediaTypes.indexOf(item.file.type)>=0)) {
					this.item = item;
					item.upload();
				}
			}
		}
		this.uploader.onBeforeUploadItem = (item) => {
			item.withCredentials = false;
		}
		this.uploader.onBuildItemForm = (item: FileItem, form: any) => {
			form.append('filename' , item.file.name);
			if (item.file.type)
				form.append('mimetype' , item.file.type);
			else if (item.file.name.endsWith(".glb"))
				form.append('mimetype' , "model/gltf-binary");
			else 
				form.append('mimetype' , "application/octet-stream");
			console.log("form for file", form);
			
		};
	  
		this.uploader.onSuccessItem = (item: FileItem, response: any, status: any, headers: any) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				let result : MediaFileDescription = new MediaFileDescription();
				Object.assign(result,JSON.parse(response));
				result.mediaUrl = reader.result;
				this.currentFile = result;
				this.mediaId = result.id;
				this.updateEnabled = false;
				this.initFileUploader();
				this.onMediaUpdate.emit(result);
			}, false);
			reader.readAsDataURL(item._file);
		}
	}

	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e && this.updateEnabled;
	}

	clickOnDropzone($event) {
		this.fileInputElement.nativeElement.click();	
	}
	get iconToShow() {
		return (this.updateEnabled)? this.iconSave: this.iconEdit;
	}
	clickOnshowFile($event: MouseEvent) {
		$event.preventDefault();
		$event.stopPropagation();

		this.updateEnabled = !this.updateEnabled;
	}
	_removeImage() {
		this.currentFile = null;
		this.mediaId = null;
		this.updateEnabled = true;
		this.onMediaUpdate.emit(null);
	}
	removeImage($event: MouseEvent) {
		$event.preventDefault();
		$event.stopPropagation();
		if (this.notShowAlertOnFileDelete) {
			this._removeImage();
		}
		else {
			let callback = (data) => {
				if (data) {
					this._removeImage();
				}
			}
			this._modalService.showConfirm("common.dialogs.alertRemoveImageTitle", "common.dialogs.alertRemoveImageMessage", {
				callback: callback,
				size: "md"
			});
		}
		

		
	}
}