<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
	<ngb-panel> 
		<ng-template ngbPanelTitle>
			 <b [ngClass]="{'text-danger': !isValid}">{{groupName}}</b><button class="btn-clear" style="display: contents;" (click)="deleteGroup($event)">
				<i class="{{iconFieldRemove}} pl-2" [ngbTooltip]="'Elimina gruppo' | translate"></i>
			</button>
		</ng-template>
		<ng-template ngbPanelContent>

			<form [formGroup]="entityForm" *ngIf="entityForm">
	
				<div class="row">
					<div class="col-8">
						<input-string formControlName="groupLabel" label="Etichetta del gruppo" placeHolder="Es. Dati specifici del Lotto"></input-string>
					</div>
					<div class="col-4">
						<input-slide formControlName="isArray" label="il gruppo è definito come elenco?" [horizontal]="false">
						</input-slide>
						
					</div>
				</div>
				<div  *ngFor="let field of entityForm.get('fields').controls; let i=index" >
					<hr class="mt-1 mt-sm-2">
					<form [formGroup]="field">
						<div class="row">
							<div class="col-11">
								<div class="row">
									<div class="col-4">
										<input-string formControlName="fieldLabel" label="Etichetta del campo"></input-string>
									</div>
									<div class="col-4">
										<select-box formControlName="fieldType" label="Formato del campo" [items]="fieldTypeItems" [multiple]="false">
										</select-box>
									</div>
									<div class="col-2">
										<input-slide formControlName="fieldToCertify" label="campo da certificare" [horizontal]="false">
										</input-slide>
									</div>
									<div class="col-2">
										<input-slide formControlName="hidden" label="Nascondi campo in elenco" [horizontal]="false">
										</input-slide>
									</div>
								</div>
								<div class="row">
									<div class="col-6">
										
										<input-string *ngIf="fieldIsString(field)" formControlName="defaultValue" label="Valore di default"></input-string>
										<input-date *ngIf="fieldIsDate(field)" utc="false" formControlName="defaultValue" label="Valore di default"></input-date>
										<input-string *ngIf="fieldIsSelect(field)" formControlName="defaultValue" label="Valore di default"></input-string>
										<input-string *ngIf="fieldIsMultiSelect(field)" formControlName="defaultValue" label="Valore di default"></input-string>
										<input-file  *ngIf="fieldIsFile(field)" notShowAlertOnFileDelete="true"
											formControlName="defaultValue"
											[possibileMediaTypes]="possibileMediaTypes"
											label="file di default di default"></input-file>
									</div>
									<div class="col-6" *ngIf="!disablePossibleValues(field)">
										<input-tags class="tagStyle" formControlName="possibleValues" label="valori possibili"></input-tags>
									</div>
								
								</div>
							</div>
							<div class="col-1">
								<button class="btn-clear" (click)="defineConstraint(field)">
									<i  class="{{iconSetting}}" placement="top" [ngbTooltip]="'modifica vincoli per il campo ' + field.value.fieldLabel "></i>
								</button>
								<button class="btn-clear" (click)="removeField(i)">
									<i  class="{{iconFieldRemove}}" placement="top" [ngbTooltip]="'elimina campo ' + field.value.fieldLabel "></i>
								</button>
								
							</div>
						
						</div>
			
					</form>
				</div>
				<div class="row text-right" >
					<div class="col-12">
						<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="addField(null)">Aggiungi nuovo campo</button>
					</div>
				</div>
			</form>
		</ng-template>
	</ngb-panel>
</ngb-accordion>
