import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { iconAdd, iconTrash } from "app/common/utils/icons.utils";
import { RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { environment } from 'environments/environment';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { BlockChainService } from 'app/services/blockchain.service';
import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { EntityCertificationBaseFormPage } from 'app/pages/forms/EntityCertificationBaseFormPage';
import { FORM_SUBMISSION_RESULT } from 'app/pages/forms/routing-constants';

@Component({
    selector: 'edit-base-product-page',
    templateUrl: './edit-base-product.page.html'
})
export class EditBaseProductPage extends EntityCertificationBaseFormPage implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconAdd = iconAdd;
	iconDelete = iconTrash;
	editorOptions;
	public currentEntityDef: any = {};
	public entityForm: FormGroup = null;	
	public additionalFields: FormArray = null;

	ngOnInit() {
		this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];

			let domain = params["domain"];
			this.currentDomainConfig = domainConfig[domain];
			
			if (id != undefined || id != null) {
				this._restaurantProvider.getRestaurant(domain,id).subscribe((result) => {
					this.initForm(result);					
					this.entityForm.patchValue(this.unmarshalData(result));
				})
			}
			else {
				this.initForm();
			}
		});
	}

    constructor( _chainService: BlockChainService, private _activatedRoute: ActivatedRoute, _fb: FormBuilder, _restaurantProvider: RestaurantProvider, private _router: Router, _userProfileService: ProfileService, private _modalService: ModalService) {
		super(_restaurantProvider, _chainService, _fb, _userProfileService);
    }


	addField = (value?: any) => {
		let additional = this._fb.group({
			fieldLabel: [null, CommonValidators.required],
			fieldValue: [null, CommonValidators.required]
		})
		if (value) additional.patchValue(value);
		this.additionalFields.push(additional);
	}
	removeField = (index: number) => {
		this.additionalFields.removeAt(index);

	}

	public get title() {
		return "Editor Prodotto";
	}
	private initForm = (initData?: any) => {
		this.additionalFields = this._fb.array([]);
		if (initData && initData.properties) {
			initData.properties.forEach(element => {
				this.addField();
			});
		}

		let entityFormDef: any = {
			id: [null],
			entityType: [null, CommonValidators.required],
			entityName: [null, CommonValidators.required],
			landingPageUrl: [null],
			needsCertificationInChain: [false],
			properties: this.additionalFields
		};
		
		this.entityForm = this._fb.group(entityFormDef);
	}

	private marshalData = (data: any): any => {
		return data;		
	}

	private unmarshalData = (data: any): any => {
		return data;
	}

	
    public save(certData?: boolean) {
        if (this.entityForm.valid) {
            this._restaurantProvider.saveOrUpdateRestaurant(this.currentDomainConfig.domainId, this.marshalData(this.entityForm.value))
			.pipe(
				catchError(error => {
					this._modalService.showErrors(["Si è verificato un errore durante il salvataggio dei dati."]);
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					if (certData) {
						this.certifyData(this.currentEntityDef, result)
							.catch((error) => {
								this._modalService.showErrors(["Si è verificato un errore durante la fase di certificazione dei dati. Si prega di riprovare più tardi o contattare il servizio clienti."]);
							})
							.then((certResult) => {
								this._activatedRoute.queryParams.subscribe(qParam => {
									if (qParam.returnUrl) {
										this._router.navigateByUrl(qParam.returnUrl);
									}
									else {
										if (result) {
											this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
										}
										else {
											this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
										}
									}
								})
							})
					}
					else {
						this._activatedRoute.queryParams.subscribe(qParam => {
							if (qParam.returnUrl) {
								this._router.navigateByUrl(qParam.returnUrl);
							}
							else {
								if (result) {
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
								}
								else {
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
								}
							}
						})
					}
				},
				(error) => {
					this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
				}
			)
        }
		else {
		}
    }

	public get logoImage(): string {
		return environment.domainConfig.companyLogo;
	}
	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.valid;
	}
}


