import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { iconAdd, iconTrash } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FORM_SUBMISSION_RESULT } from '../routing-constants';
import { environment } from 'environments/environment';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { NewCertificationBaseFormPage } from '../NewCertificationBaseFormPage';
import { BlockChainService } from 'app/services/blockchain.service';
import { ProfileService } from 'app/services/profile.service';
import moment from 'moment';

@Component({
    selector: 'hqfagricola-form-page',
    templateUrl: './hqfagricola-form.page.html'
})

export class HQFFormPage extends NewCertificationBaseFormPage implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconAdd = iconAdd;
	iconDelete = iconTrash;
	editorOptions;

	public entityForm: FormGroup = null;
	
	public lotInfo: FormGroup = null;
	public productInfo: FormGroup = null;

	

	ngOnInit() {
		this.certificationConfig = environment.certificationConfig.DEFAULT_CONFIG;
		this.currentDomainConfig = domainConfig.hqfagricola_demo;
		this._activatedRoute.params.subscribe(params => {
			let id = params['id'];
			if (id != undefined || id != null) {

				this._restaurantProvider.getRestaurant(DOMAINS_CONFIG.HQFAgricola,id).subscribe((result) => {
					this.initForm(result);					
					this.entityForm.patchValue(this.unmarshalData(result));
				})
			}
			else {
				this.initForm();
			}
		});
	}

    constructor( _chainService: BlockChainService, private _activatedRoute: ActivatedRoute, _fb: FormBuilder, _restaurantProvider: RestaurantProvider, private _router: Router, _userProfileService: ProfileService) {
		super(_restaurantProvider, _chainService, _fb, _userProfileService);
    }


	private initForm = (initData?: any) => {
		
		
		this.lotInfo =this._fb.group({
			lotNumber: ["26", CommonValidators.required],
			slaughterDate: ["2021/09/24", CommonValidators.required],
			slaughterSite: ["Mattatoio comunale di Viterbo", CommonValidators.required],
			laboratory: ["Laboratorio di sezionamento di HQF", CommonValidators.required],
			expiryDate: ["2021/11/24", CommonValidators.required]
		});
		this.productInfo = this._fb.group({
			origin: ["Incrocio tra un Angus Aberdeen in purezza ed una Maremmana o Frisona Italiana", CommonValidators.required],
			farm: ["6/7 mesi allo stato brado", CommonValidators.required],
			diet: ["10-12 mesi di alimentazione “Marango Fioc” esclusiva HQF", CommonValidators.required],
			dietSpec: ["paglia, insilato di mais, semi di lino, melassa di barbarbietola, grassi vegetali", CommonValidators.required]
		})

		this.entityForm =this._fb.group({
			id: [null],
			companyName: ["HQF Agricola", CommonValidators.required],
			region: ["Lazio", CommonValidators.required],
			companyCoordinates: [`42°19'32.4"N 11°58'03.7"E"`, CommonValidators.required],
			province: ["Viterbo", CommonValidators.required],
			product: ["marango italiano", CommonValidators.required],
			giftDescription: [null, CommonValidators.required],

			lotInfo: this.lotInfo,
			productInfo: this.productInfo
		});

		this.entityForm.valueChanges.subscribe((v) => {console.log(v);
		})
	}

	private marshalData = (data: any): any => {
		return data;		
	}

	private unmarshalData = (data: any): any => {
		return data;
	}

	
    public save() {
        if (this.canSaveForm) {
			let toSave = this.entityForm.value;
			toSave.lotInfo.slaughterDate = moment(this.entityForm.value.lotInfo.slaughterDate).format("YYYY/MM/DD");
			toSave.lotInfo.expiryDate = moment(this.entityForm.value.lotInfo.expiryDate).format("YYYY/MM/DD");
            this._restaurantProvider.saveOrUpdateRestaurant(DOMAINS_CONFIG.HQFAgricola, this.marshalData(toSave))
			.pipe(
				catchError(error => {
					return of(null);
				})
			)
			.subscribe(
				(result) => {
					
					this.certifyData(result).then((certResult) => {
						this._activatedRoute.queryParams.subscribe(qParam => {
							if (qParam.returnUrl) {
								this._router.navigateByUrl(qParam.returnUrl);
							}
							else {
								if (result) {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_OK]);
								}
								else {
									//console.log("Salvataggio completato correttamente");
									this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
								}
							}
						})
					})
				},
				(error) => {
					//console.log("Salvataggio completato correttamente");
					this._router.navigate([FORM_SUBMISSION_RESULT, SubmissionResult.RESULT_ERROR]);
				}
			)
        }
		else {
			//console.log("form not valid");
			
		}
    }


	public get logoImage(): string {
		return environment.domainConfig.companyLogo;
	}

	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.valid;
	}
}


