<form *ngIf="usersForm" [formGroup]="usersForm">
	<div class="row">
		<div class="col-12">
			<select-box formControlName="enabledUsers" label="Utenti abilitati a {{company.name}} come amministratori" [items]="usersList" [multiple]="true">
			</select-box>
		</div>
	</div>
	<div class="row text-right" >
															
		<div class="col-12 mt-2">
			<button type="button" [disabled]="!usersForm.valid" class="btn btn-primary mr-sm-2 mb-1" (click)="save()">Salva permessi</button>
		</div>
	</div>
	
</form>
