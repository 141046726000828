import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class ChatGPT {
	private threadId;
	private latestRun;
	constructor(private _httpClient: HttpClient) {

	}

	public startThread = (): Observable<string> => {
		if (this.threadId) return of(this.threadId);

		return this.post(environment.services.chatgptUrl + "threads", {}, "json", {
			"OpenAI-Beta": "assistants=v1"
		}).pipe(
			map((response) => {
				let result = "";
				if (response && response.id) {
					this.threadId = response.id;
				}
				console.log("response: " + result);
				
				return result;
			})
		)

	}

	public addMessageToThread = (message: string): Observable<string> => {
		return this.post(environment.services.chatgptUrl + "threads/"+this.threadId+"/messages", {
			role: "user",
			content: message
		}, "json", {
			"OpenAI-Beta": "assistants=v1"
		}).pipe(
			map((response) => {
				
				
				return response.id;
			})
		)
	}

	public runThread = (): Observable<string> => {
		return this.post(environment.services.chatgptUrl + "threads/"+this.threadId+"/runs", {
			assistant_id: "asst_XQIzDUr2MfEDneZGF2d68jBf",
			model: "gpt-4-1106-preview"
		}, "json", {
			"OpenAI-Beta": "assistants=v1"
		}).pipe(
			map((response) => {
				let result = "";
				if (response && response.id) {
					this.latestRun = response.id;
				}
				console.log("response: " + result);
				
				return result;
			})
		)
	}

	public retriveRunStatus = (): Observable<string> => {
		return this.get(environment.services.chatgptUrl + "threads/"+this.threadId+"/runs/"+this.latestRun, "json", {
			"OpenAI-Beta": "assistants=v1"
		}).pipe(
			map((response) => {
				let result = "";
				if (response) {
					return response.status;
				}				
				return result;
			})
		)
	}
	
	public getMessages = (): Observable<any> => {
		return this.get(environment.services.chatgptUrl + "threads/"+this.threadId+"/messages", "json", {
			"OpenAI-Beta": "assistants=v1"
		}).pipe(
			map((response) => {
				return response;
			})
		)
	}

	public respondTo = (request: string): Observable<string> => {
		return this.post(environment.services.chatgptUrl + "chat/completions",
			{"model": "gpt-4-1106-preview", "messages": [{ "role": "user", "content": request}]}
			// {"model": "gpt-3.5-turbo", "messages": [{ "role": "user", "content": request}]}
		).pipe(
			map((response) => {
				let result = "";
				if (response && response.choices && response.choices[0] && response.choices[0].message) {
					result = response.choices[0].message.content
				}
				console.log("response: " + result);
				
				return result;
			})
		)
	}


	private get = <T = any>(url:string, responseType?: "json" | "text" | "blob" | "arraybuffer", customHeaders: any = {}, observe: string="body"): Observable<T> => {
		return this._httpClient.get<T>(url,this.getHeaders(responseType,customHeaders,false, observe)).pipe(
			catchError(this.errorHandler)
		);
	}
		

    private post = <T = any>(url:string, data : any, responseType?: "json" | "text" | "blob" | "arraybuffer", customHeaders: any = {}): Observable<T> => {
        return this._httpClient.post<T>(url,data, this.getHeaders(responseType,customHeaders,false)).pipe(
			catchError(this.errorHandler)
		);
    }

    
	private errorHandler(error: HttpErrorResponse) {
		return throwError(error.message || "UNDEFINED_SERVER_ERROR");
    }
	private getHeaders = (responseType: "json" | "text" | "blob" | "arraybuffer", customHeaders: any = {}, reportProgress :boolean = false, observe: string="body"):
		{
        	headers?: HttpHeaders | {[header: string]: string | string[];};
			observe?: 'body';
			params?: HttpParams | {[param: string]: string | string[];};
			reportProgress?: boolean;
			responseType?: 'json';
			withCredentials?: boolean;
		} => {        
			let requestOptions: any = {};
			requestOptions.responseType = responseType || "json";
			requestOptions.observe = observe;
			requestOptions.reportProgress = reportProgress;

			let headers = new HttpHeaders();
	        for (let prop in customHeaders) {
				headers = headers.set(prop, customHeaders[prop]);
        	}
			headers = headers.set("Authorization", "Bearer " + environment.CHAT_GPT_API_KEY);
        	requestOptions.headers = headers;			
			return requestOptions;
    }

	public getAudio = (message): Observable<any> => {
		return this.post(environment.services.chatgptUrl + "audio/speech",
			{
				"model": "tts-1",
				"input": message,
				"voice": "alloy"
			},
			"arraybuffer"
		).pipe(
			map((response) => {
				// let result = "";
				// if (response && response.choices && response.choices[0] && response.choices[0].message) {
				// 	result = response.choices[0].message.content
				// }
				console.log("response: " + response);
				
				return new Blob([response], { type: "audio/mp3" });
			})
		)
	}

	public convertAudioIntoText = (media: Blob): Observable<any> => {

		const formData: FormData = new FormData();
		
		formData.append('file', media);
		formData.append('model', "whisper-1");
		
		return this.post(environment.services.chatgptUrl + "audio/transcriptions",
		formData,
		"json"
	).pipe(
		map((response) => {
			// let result = "";
			// if (response && response.choices && response.choices[0] && response.choices[0].message) {
			// 	result = response.choices[0].message.content
			// }
			console.log("response: " + response);
			if (response)
				return response.text;
			return null;
		})
	)
	}

	private cleanText = (text: string): string => {
		const TextCleaner = require('text-cleaner');
		return TextCleaner(text).remove().condense().stripHtml().replace(/\*/g, '').replace(/【.*】/g,'').replace(/à/g,'a\'').valueOf();
	}

	public speechOnHyGenSession = (sessionId, text): Observable<any> => {
		return this.post(`${environment.HEY_GEN_URL}/v2/realtime/task`,
			{"session_id": sessionId, "text": this.cleanText(text)},
			"json",{ "X-Api-Key": environment.HEY_GEN_APY_KEY}
			)
	}

	private baseUrl = environment.services.apiBasePath + "/HEY_GEN/SESSION/657ade7c4eb4da506572fed4";

	public getHeyGenSessionId = (): Observable<any> => {
		let url = this.baseUrl;
		return this.get(url);
	}

	
}