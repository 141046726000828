<modal title="{{title}}" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div class="row formContent" *ngIf="dataLoaded">
			<div class="col-12">
				<form [formGroup]="entityForm" *ngIf="entityForm">
					
					<div class="row">
						<div class="col-12">
							<input-slide formControlName="required" label="Rendi campo obbligatorio" [horizontal]="true">
							</input-slide>
						</div>
					</div>
					
				</form>
				
				<p>&nbsp;</p>
				
				<div *ngIf="!entityForm.valid" class="subtitle-form">
					<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
				</div>
			</div>
		</div>
	</div>
	
    <div footer>
		<div class="row text-right" *ngIf="dataLoaded">
			<div class="col-12">
				
				<button type="button" (click)="close()" class="btn btn-outline-secondary mr-sm-2 mb-1"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
				<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!canSaveForm" (click)="save()" ><i class="{{iconSave}}"></i> SALVA</button>
				
			</div>
		</div>
									

    </div>
</modal>

