

<modal title="{{numberOfAvailableItems}} NFT Collegati" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div class="row" *ngIf="dataLoaded">
			<div class="col-12">
				<list-table #listTable [columns]="tableColumns" [manager]="dataTableManager" [actions]="tableActions">
				</list-table>
			</div>
		</div>
		
	</div>
	
    <div footer>
		<!-- <div class="row text-right" >
			<div class="col-12">
				<button type="button" (click)="close()" class="btn btn-outline-secondary mr-sm-2 mb-1"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
			</div>
		</div> -->

    </div>
</modal>
