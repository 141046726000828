<modal  title="{{title | translate}}" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div class="row" *ngIf="data">
			<div class="col-12 d-flex flex-sm-row flex-column justify-content-center">
				<qrcode #code elementType="img" [qrdata]="url" [width]="256" [level]="'M'" [errorCorrectionLevel]="'M'"></qrcode>
			</div>
		</div>
	</div>
	
    <div class="text-right" footer>
		<button type="button" (click)="close()" class="btn btn-outline-secondary mr-sm-2 mb-1"><i class="{{iconCancel}}"></i> {{'common.close' | translate}}</button>
		<button type="button" (click)="saveAsImage()" class="btn btn-outline-primary mr-sm-2 mb-1"><i class="{{iconSave}}"></i> {{'scarica QRCode' | translate}}</button>
    </div>
</modal>