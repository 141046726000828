import { environment } from "environments/environment";

export enum FieldType {
	UUID="UUID",
	STRING="STRING",
	DESCRIPTION="DESCRIPTION",
	DATE="DATE",
	// DATETIME = "DATETIME",
	FILE="FILE",
	IMAGE = "IMAGE",
	SINGLE_SELECT = "SINGLE_SELECT",
	MULTIPLE_SELECT = "MULTIPLE_SELECT",
	INTEGER = "INTEGER",
	DECIMAL = "DECIMAL"
}
export enum ValidatorType {
	REQUIRED = "REQUIRED",
	FORMAT_EMAIL = "FORMAT_EMAIL",
	FORMA_URL = "FORMA_URL"
}
// export class KeyValue {
// 	key: string;
// 	value: any;
// }
export class FieldValidator {
	validatorType: ValidatorType;
	constraint: [] = [];
}
export class CertifiedEntity {
	productToCertify: boolean = true;
	blockchainUrlVerify?: string;
	certIdField: string = "id";
	certifiedInDateUTC?: string;
	certified?: boolean = false;
}
export class FieldDefinition {
	fieldId: string;
	fieldLabel: string;
	fieldType: FieldType;
	defaultValue?: any;
	possibleValues?: string[] = [];
	validators: FieldValidator[] = [];
	hidden: boolean = false;
	fieldToCertify: boolean = true;	
}

export class GroupDefinition {
	groupId: string;
	groupLabel: string;
	isArray: boolean = false;
	fields: FieldDefinition[]=[];
}

export class EntityDefinition {
	entityId: string;
	name: string;
	groups: GroupDefinition[] = [];
	landingPageUrl?: string;
	certVerifyerUrl?: string;
	
	hasLinkedNFT: boolean = true;
	// linkedEntitiesDef: EntityDefinition[] = [];
}


export class ProductFieldDef {
	name:string;
	value: string;
	canBeDelete: boolean = true;
}
export class Product extends CertifiedEntity {
	id: string;
	productId: string;
	name: string;
	image_3d?: any;
	image: any;
	productFields: ProductFieldDef[]=[];
	landingPageUrl?: string;
	certVerifyerUrl?: string;
	lastUpdateDate?: any;
	linkedEntity: EntityDefinition[]=[];
}

export class Company {
	id: string;
	companyId: string;
	logo: any;
	name: string;
	address: string;
	cap: string;
	city: string;
	province: string;
	coordinates?: any;
	email?: string;
	phoneNumber?: string;

	additionalInfo?: string;
	lastUpdateDate: string;
	products: Product[] = [];

// - produttore
// - tipologia filiera prevalente: da valutare

// - numero prodotti
// - elenco prodotti (fino a 20 campi metterei, come “prodotto 1”, “prodotto 2”, ecc..

// - numero referenze
// - elenco referenze (fino a 20 campi metterei, come “referenza 1”, “referenza 2”,ecc.. anche se alcune aziende hanno una vasta gamma di prodotti. Comunque sia questo sistema potrà darci un’idea generale della dimensione e della tipologia aziendale)


}



// classi da non usare
class DomainEntityProperty {
	fieldLabel: string;
	fieldValue: any;
}

export class DomainEntity {
	id: string;
	entityType: string; // es. CarnaroliClassicoGranRiserva
	entityName: string; // es "RISO BUONO Carnaroli Classico Gran Riserva"
	landingPageUrl?: string; // es "https://www.posti.world/risobuono?id={id}",
	needsCertificationInChain: boolean = false;
	
	properties: DomainEntityProperty[];
	linkedEntity: DomainEntity[] = [];
}








export var templateLotto: EntityDefinition = {
	"entityId": "lotto",
	"name": "Lotto",
	"landingPageUrl": environment.domainConfig.defaultEntityLandingPagePatternURL,
	"certVerifyerUrl": environment.domainConfig.defaultCertPagePatternURL,
	"groups": [
		{
			"isArray": false,
			"fields": [
				{
					"fieldId": "719be5e0-39b8-41b6-bf97-7a86428f164d",
					"fieldLabel": "data produzione",
					"fieldType": FieldType.STRING,
					"possibleValues": [

					],
					"validators": [
						{
							"validatorType": ValidatorType.REQUIRED,
							"constraint": [

							]
						}
					],
					"hidden": false,
					"fieldToCertify": true
				},
				{
					"fieldId": "790acd4f-4e1d-4493-b5c6-bc34844ea6cc",
					"fieldLabel": "data confezionamento",
					"fieldType": FieldType.STRING,
					"possibleValues": [

					],
					"validators": [
						{
							"validatorType": ValidatorType.REQUIRED,
							"constraint": [

							]
						}
					],
					"hidden": false,
					"fieldToCertify": true
				}
			],
			"groupId": "75d655a9-dccb-43be-b266-a091031bb5ce",
			"groupLabel": "Informazioni specifiche per il Lotto"
		}
	],
	hasLinkedNFT: true
	// linkedEntitiesDef: [
	// 	{
	// 		"entityId": "NFT",
	// 		"name": "NFT Collegati al lotto",
	// 		"landingPageUrl": "https://www.posti.world/{productId}?id={id}",
	// 		"certVerifyerUrl": "https://verifier.posti.world/{companyId}/{id}/verify",
	// 		"groups": [
	// 			{
	// 				"isArray": false,
	// 				"groupId": "75d655a9-dccb-43be-b266-a091031bb5ce",
	// 				"groupLabel": "Informazioni specifiche dell'NFT",
	// 				"fields": [
	// 					{
	// 						"fieldId": "719be5e0-39b8-41b6-bf97-7a86428f164d",
	// 						"fieldLabel": "descrizione",
	// 						"fieldType": FieldType.STRING,
	// 						"possibleValues": [

	// 						],
	// 						"validators": [
	// 							{
	// 								"validatorType": ValidatorType.REQUIRED,
	// 								"constraint": [

	// 								]
	// 							}
	// 						],
	// 						"hidden": false,
	// 						"fieldToCertify": true
	// 					}
	// 				]
	// 			}
	// 		]
	// 	}
	// ]
}