<modal title="{{title}}" (onClose)="close()" headerBgColor="gradient-man-of-steel">
	<div body>
		<div class="row formContent">
			<div class="col-12">
				<form [formGroup]="entityForm" *ngIf="entityForm">
					
					<div class="row">
						<div class="col-6">
							<input-file notShowAlertOnFileDelete="true"
								[disabled]="productDataReadonly"
									formControlName="image"
									[possibileMediaTypes]="possibileMediaTypes"
									label="Immagine del prodotto"></input-file>
						</div>
						<div class="col-6">
							<input-file notShowAlertOnFileDelete="true"
								[disabled]="productDataReadonly"
									formControlName="image_3d"
									label="Immagine 3D"></input-file>
						</div>
					</div>
					<div class="row" *ngIf="isAdmin">
						<div class="col-12">
							<input-string formControlName="productId" label="Identificativo univoco" [disabled]="true" [required]="true"></input-string>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<input-string [disabled]="productDataReadonly" formControlName="name" label="Nome*"></input-string>
						</div>
					</div>
					
					<div class="row">
						<div class="col-12">
							<input-string *ngIf="isAdmin" formControlName="certVerifyerUrl" label="Url alla pagina di verifica certificato"></input-string>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<input-string  *ngIf="isAdmin" formControlName="landingPageUrl"  label="Url alla landing page"></input-string>
						</div>
					</div>
					<div >
						<h3>Dati specifici del prodotto</h3>
					</div>
					<div  *ngFor="let field of entityForm.get('productFields').controls; let i=index" >
						<form [formGroup]="field">
							<div class="row">
								<div class="col col-4">
									<input-string [disabled]="productDataReadonly" formControlName="name" label="Etichetta del campo"></input-string>
									
								</div>
								<div class="col col-7">
									<input-string [disabled]="productDataReadonly" formControlName="value" label="Valore del campo"></input-string>
								</div>
								<div class="col col-1 d-flex align-items-center align-items-end" *ngIf="!productDataReadonly && field.value.canBeDelete">
									<button type="button" class="btn-clear cursor-pointer" (click)="removeField(i)" >
										<i class="{{iconDelete}}" placement="top" [ngbTooltip]="'Elimina campo ' + field.value.name "></i>
										
									</button>
									<!-- <button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="removeField(i)">elimina</button> -->
								</div>
							
							</div>

						</form>
					</div>
					
					
					<div class="row text-right" *ngIf="!productDataReadonly" >
						<div class="col-12">
							<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="addField()">Aggiungi nuovo campo</button>
						</div>
					</div>
				</form>
				
				<div *ngIf="!entityForm.valid" class="subtitle-form">
					<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
				</div>
				<hr>
				
				<div>
					<h3>Informazioni del lotto</h3>
				</div>
				<div  *ngFor="let field of entityFields.controls; let i=index" >
					<form [formGroup]="field">
						<div class="row">
							<div class="col col-4">
								<input-string [disabled]="productDataReadonly" formControlName="name" label="Etichetta del campo"></input-string>
							</div>
							<div class="col col-7">
								<input-string formControlName="value" label="Valore del campo"></input-string>
							</div>
							<div class="col col-1 d-flex align-items-center align-items-end" *ngIf="field.value.canBeDelete">
								<button type="button" class="btn-clear cursor-pointer" (click)="removeEntityField(i)" [disabled]="productDataReadonly" >
									<i class="{{iconDelete}}" placement="top" [ngbTooltip]="'Elimina campo ' + field.value.name "></i>
									
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="row text-right" >
					<div class="col-12">
						<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="addEntityField()" [disabled]="productDataReadonly" >Aggiungi nuovo campo al lotto</button>
					</div>
				</div>
				<hr>
				<div>
					<h3>NFT collegati al lotto</h3>
				</div>
				<form [formGroup]="linkedNFT">
					<div class="row">
						<div class="col col-12">
							<input-int formControlName="numbers" label="Numero di NFT da generare"></input-int>
						</div>
					</div>
					<div class="row">
						<div class="col col-12">
							<input-string formControlName="descrizione" label="Descrizione" rows="4"></input-string>
						</div>
					</div>
				</form>

			</div>
		</div>
	</div>
	
    <div class="" footer>
		<!-- <div class="progress">
			<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: {{progress}}%" aria-valuenow="{{progress}}" aria-valuemin="0" aria-valuemax="100">{{progress}}</div>
		  </div> -->
		<form [formGroup]="walletForm">
			<div class="row text-left">
				<div class="col-12">
					<input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
				</div>
			</div>
			
		</form>
		<div class="row text-right" >
			<div class="col-12">
				
				<button type="button" (click)="close()" class="btn btn-outline-secondary mr-sm-2 mb-1"><i class="{{iconCancel}}"></i> {{'common.cancelButtonLabel' | translate}}</button>
				<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!canSaveForm" (click)="save(false)" ><i class="{{iconSave}}"></i> SALVA</button>
				<button type="button" class="btn btn-primary mr-sm-2 mb-1" [disabled]="!canSaveAndCertify" (click)="save(true)" ><i class="{{iconSave}}"></i> SALVA E CERTIFICA</button>
				
			</div>
		</div>
									

    </div>
</modal>


<!-- 

<base-page>
    <ng-container page-body>
        <div class="card form-card">
			<div class="subtitle-form">
				<h1>{{title}}</h1>
			</div>
			<aw-wizard *ngIf="entityForm">
				<aw-wizard-step stepTitle="Dati generali">
					<div class="card-header logo-form" style="background-color: white;">
						<img class="" [src]="logoImage">
					</div>
					<div class="card-content form-content">
						<div class="card-body">
							
						</div>
					</div>
				</aw-wizard-step>
				<aw-wizard-step [stepTitle]="'Riepilogo'">
					<div class="card-header logo-form" style="background-color: white;">
						<img class="" [src]="logoImage">
					</div>
					<div class="card-content form-content">
						<div class="card-body">
							<div class="row formContent">
								<div class="col-12">
									<div class="subtitle-form">
										<h3>Definizione dei dati generali</h3>
									</div>
									<div class="row"><strong>Identificativo:</strong> {{entityForm.value.entityType}}</div>
									<div class="row"><strong>Nome:</strong> {{entityForm.value.entityName}}</div>
									<div class="row"><strong>Url alla landing page:</strong> {{entityForm.value.landingPageUrl}}</div>
									<div class="row"><strong>Questi dati possono essere certificait:</strong> {{(entityForm.value.needsCertificationInChain)?'SI':'NO'}}</div>
									
									<div class="subtitle-form" *ngIf="additionalFields.value.length > 0">
										<h3>Dati specifici</h3>
									</div>

									
									<div class="row" *ngFor="let field of additionalFields.value" >
										<div class="col-12 text-white">
											<strong>{{field.value?.fieldLabel}}:</strong> {{field.value?.fieldValue}}
										</div>
									</div>
									
									
									
									<form [formGroup]="walletForm">
										<div class="subtitle-form">
											<h3>chiavi di sicurezza</h3>
										</div>
										
										<div class="row">
											<div class="col-12 ">
												<input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
											</div>
										</div>
										
									</form>
									<button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!entityForm.valid" (click)="save(false)" >SALVA</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save(true)" >SALVA E CERTIFICA</button>
									<br>
									<div *ngIf="!canSaveForm" class="subtitle-form">
										<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</aw-wizard-step>

			</aw-wizard>
        </div>
    </ng-container>
</base-page> -->