import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthService } from './services/auth.service';
import { mergeMap } from 'rxjs/operators';
import { ProfileService } from './services/profile.service';
import { allData } from './all-data';

export function loadAuthenticationConfiguration(authService: AuthService, profileService: ProfileService) {
    return () => {
        return authService.verifyLogin()
            .pipe(mergeMap(result => {
                if (result) {
                    return profileService.loadUserProfile();
                }
                else {
                    authService.startLogin();
                }

            }))
            .toPromise();
    }
}


@NgModule({
    imports: [
      
        OAuthModule.forRoot()
    ]
})
export class StartupModule {
    public static forRoot(): ModuleWithProviders<StartupModule> {
		// let data: any = {};
		// allData.forEach((el) => {
		// 	if (el.domains) {
		// 		if (el.domains.length > 1) console.log("len > 1 for ", el);
				
		// 		el.domains.forEach((domain) => {
		// 			if (data[domain]) data[domain]++;
		// 			else data[domain] = 1;
		// 		})
				
		// 	}
		// })
		// console.log("Domain: ", data);
		
        return {
            ngModule: StartupModule,

            providers: [
				ProfileService,
                AuthService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: loadAuthenticationConfiguration,
                    deps: [AuthService, ProfileService],
                    multi: true
                }
            ]
        };
    }
    constructor(
        @Optional() @SkipSelf() parentModule?: StartupModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('StartupModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
