
	<form [formGroup]="formGroup" *ngIf="loaded">
		<input-string *ngIf="fieldDef.fieldType == 'STRING'" [formControlName]="fieldDef.fieldLabel" [label]="fieldDef.fieldLabel"></input-string>
		<input-string *ngIf="fieldDef.fieldType == 'DESCRIPTION'" [formControlName]="fieldDef.fieldLabel" [label]="fieldDef.fieldLabel" rows="5"></input-string>
		<input-date *ngIf="fieldDef.fieldType == 'DATE'"  [formControlName]="fieldDef.fieldLabel" [label]="fieldDef.fieldLabel"></input-date>
		<!-- <input-date *ngIf="fieldDef.fieldType == 'DATETIME'"  [formControlName]="fieldDef.fieldLabel" [label]="fieldDef.fieldLabel"></input-date> -->
		<input-file *ngIf="fieldDef.fieldType == 'FILE'"  [formControlName]="fieldDef.fieldLabel" [label]="fieldDef.fieldLabel" [possibileMediaTypes]="possibileMediaTypes"></input-file>
		<input-file *ngIf="fieldDef.fieldType == 'IMAGE'"  [formControlName]="fieldDef.fieldLabel" [label]="fieldDef.fieldLabel" [possibileMediaTypes]="possibileMediaTypes"></input-file>
		
		<select-box *ngIf="fieldDef.fieldType == 'SINGLE_SELECT' || fieldDef.fieldType == 'MULTIPLE_SELECT'" 
			[formControlName]="fieldDef.fieldLabel"
			[label]="fieldDef.fieldLabel"
			[items]="possibleValues" [multiple]="fieldDef.fieldType == 'MULTIPLE_SELECT'">
		</select-box>
	
		<input-int *ngIf="fieldDef.fieldType == 'INTEGER'"  [formControlName]="fieldDef.fieldLabel" [label]="fieldDef.fieldLabel" ></input-int>
		<input-decimal *ngIf="fieldDef.fieldType == 'DECIMAL'"  [formControlName]="fieldDef.fieldLabel" [label]="fieldDef.fieldLabel" ></input-decimal>
	
	</form>
	