import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectListitem } from 'app/common/models/models';
import { forkJoin, of } from 'rxjs';

import { iconCancel, iconEdit, iconPropertiesEdit, iconSave, iconTrash } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FORM_SUBMISSION_RESULT } from '../../forms/routing-constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Restaurant } from 'app/models/restaurant.model';

// declare var clientLib;
import { saveAs } from 'file-saver';
import { CertificationConfig } from '../../forms/CertificationBaseFormPage';
import { environment } from 'environments/environment';
import { BlockChainService } from 'app/services/blockchain.service';
import moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { ProfileService } from 'app/services/profile.service';
import { ACR, ACRService } from 'app/services/acl.service';
import { UserProfile } from 'app/models/profile.models';

export class EntityDescription {
	entityName: string;
	id: any;
	entityDomain: DOMAINS_CONFIG;
}
@Component({
    selector: 'add-acr-to-entity',
    templateUrl: './add-acr-to-entity.modal.html'
})

export class AddACRToEntityModal  implements OnInit {

	iconCancel = iconCancel;
	iconSave = iconSave;

	acrForm: FormGroup;

	public data: EntityDescription;
	
	public get entityName() {
		if (this.data) return this.data.entityName;
		return "";
	}
	public get title() {
		if (this.data) return "condivisione di " + this.data.entityName;
		return "";
	}

	constructor(public activeModal: NgbActiveModal, private _fb: FormBuilder, private _authService: AuthService, private _profileService: ProfileService, private _arcService: ACRService) {
		
    }

	currentLinkedUserIds: string[] = [];
	selectUsersItems: SelectListitem[] = [];
	originalAcr: ACR[] = [];

	ngOnInit() {

		forkJoin({
			acr: this._arcService.getACRByEntityId(this.data.id),
			users: this._profileService.getAll()
		})
		.subscribe((result) => {
			if (result.acr) {
				this.currentLinkedUserIds = result.acr.map((acr) => { return acr.userId}) || [];
				this.originalAcr = result.acr;
			}
			if (result.users) {
				this.selectUsersItems = result.users.map(m => new SelectListitem(m.userId, m.firstName + " " + m.lastName + " (" + m.email+ ")"));
			}
			this.initForm(this.currentLinkedUserIds);
		})
	}

   

	

	private initForm = (currentUsers) => {
		this.acrForm = this._fb.group({
			users: [currentUsers]
		})
	}
	
	public close = (): void => {
		this.activeModal.close(false);
    }

    public save() {   
		let acrToDelete: ACR[] = [];
		let acrToSave: ACR[] = [];

		acrToDelete = this.originalAcr.filter((acr) => {
			return this.acrForm.value.users.indexOf(acr.userId) < 0;
		})

		let userToSave: string[] = this.acrForm.value.users.filter((ids) => {
			return this.currentLinkedUserIds.indexOf(ids) < 0;
		})
		acrToSave = userToSave.map((userId) => {
			let acr = new ACR();
			acr.entityDomain= this.data.entityDomain;
			acr.entityId= this.data.id;
			acr.entityName= this.data.entityName;
			acr.userId= userId;
			return acr;
		})
		
		this.activeModal.close(true);
	
		
		acrToSave.forEach((acr) => {
			this._arcService.saveOrUpdateACR(acr).subscribe((result) => {
			})
		})

		acrToDelete.forEach((acr) => {
			this._arcService.deleteACR(acr).subscribe((result) => {
			})
		})

    }
}


