<base-page>
	<ng-container page-body>
		<div class="row">
			<div class="col-12">
				<form [formGroup]="formGroup" *ngIf="formGroup">

				

							<div class="card">
								<div class="card-content">
									<div class="card-body">
										<div class="row" *ngFor="let response of responses">
											<div class="col-12" style="margin-top: 8px;">
												<b>{{response.user}}></b>&nbsp;{{response.message}}
											</div>
										</div>
										<div class="row" *ngIf="waitingResponse">
											<div class="col-12 d-flex flex-sm-row flex-column justify-content-center">
												<img src="assets/img/spinner.gif" width="20px" height="20px">
											</div>
										</div>
										<div class="row" style="margin-top: 10px;">
											<div class="col-12">
												<hr>
											</div>
										</div>
										<div class="row">
											<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
												<!-- <input-string formControlName="messageText" label="inserisci un messaggio"  [disabled]="waitingResponse">
												</input-string> -->
												<button *ngIf="!isRecording" type="submit" class="btn btn-primary mr-sm-2 mb-1"
													(click)="start()"
													
													>
													<i class="fa fa-microphone" aria-hidden="true"></i>
												</button>
												<button *ngIf="isRecording" type="submit" class="btn btn btn-danger mr-sm-2 mb-1"
													(click)="stop()"
													>
													<i class="fa fa-pause" aria-hidden="true"></i>
												</button>
												
											</div>
										</div>
										
										<!-- <div class="row">
											<div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
												<button type="submit" class="btn btn-primary mr-sm-2 mb-1"
													[disabled]="waitingResponse"
													(click)="sendQuestion()">invia richiesta</button>
											</div>
										</div> -->

										<!-- <div class="row">
											<div class="col-3"></div>
											<div class="col-6 d-flex flex-sm-row flex-column justify-content-center">
												<video src="https://www.w3schools.com/tags/movie.mp4" controls height="300" width="200" autoplay="true">
												</video>
											</div>
											<div class="col-3"></div>
										</div> -->
									</div>
								</div>
								
							</div>
						

				</form>
			</div>
		</div>
		
		<div style="position: absolute; top: -100px;">
			<audio #audioPlayer controls style="margin: 10px;"></audio>
		</div>
	</ng-container>
</base-page>