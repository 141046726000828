<base-page>
    <ng-container page-body>
        <div class="card form-card" *ngIf="referredEntity">
			<div class="subtitle-form">
				<h1>{{referredEntity.name}}</h1>
			</div>
			<aw-wizard *ngIf="referredEntity">
				<aw-wizard-step *ngFor="let group of referredEntity.groups; let i=index" [stepTitle]="group.groupLabel">
					<div class="card-header logo-form" style="background-color: white;">
						<img class="" [src]="logoImage">
					</div>
					<div class="card-content form-content">
						<div class="card-body">
							<div class="row formContent">
								<div class="col-12">
									<div class="subtitle-form">
										<h3>{{group.groupLabel}}</h3>
									</div>
									<div *ngIf="!group.isArray">
										<div class="row" *ngFor="let field of group.fields" >
											<div class="col-12">
												<entity-field [formGroup]="entityForm.get(group.groupLabel)" [fieldDef]="field"></entity-field>
											</div>
										</div>
									</div>
									<div *ngIf="group.isArray">
										<div *ngFor="let data of entityForm.get(group.groupLabel).controls; let i=index" >
											<form [formGroup]="data">
												<div class="row">
													<div class="col-11">
														<div class="row">
															<div class="col-{{12/group.fields.length}}" *ngFor="let field of group.fields">
																<entity-field [formGroup]="data" [fieldDef]="field"></entity-field>
															</div>
														</div>
													</div>
													

													<div class="col col-1 d-flex align-items-center align-items-end">
														<button type="button" class="btn-clear cursor-pointer" (click)="removeField(group, i)" >
															<i class="{{iconDelete}}" placement="top" [ngbTooltip]="'Elimina'"></i>
															
														</button>
														
													</div>
												</div>
											</form>
											
										</div>
										<div class="row text-right" >
											<div class="col-12">
												<button type="button" class="btn btn-primary mr-sm-2 mb-1" (click)="addField(group)">Aggiungi nuovo gruppo di valori</button>
											</div>
										</div>
									</div>
									

									<button type="button" *ngIf="i==0" class="wizardBtn" (click)="goToList()">INDIETRO</button>
									<button type="button" *ngIf="i>0"  class="wizardBtn" awPreviousStep>INDIETRO</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!entityForm.get(group.groupLabel).valid" awNextStep>AVANTI</button>
									<br>
									<div *ngIf="!entityForm.get(group.groupLabel).valid" class="subtitle-form">
										<p class="stepControl">Controlla di aver inserito tutti i campi obbligatori</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</aw-wizard-step>
				<aw-wizard-step [stepTitle]="'Riepilogo'">
					<div class="card-header logo-form" style="background-color: white;">
						<img class="" [src]="logoImage">
					</div>
					<div class="card-content form-content">
						<div class="card-body">
							<div class="row formContent">
								
								<div class="col-12">
									<div class="row text-center">
										<div class="col-12 "><h2 class="text-white">Riepilogo dei dati che verranno certificati</h2></div>
									</div>

									<div *ngFor="let group of getBaseCertData()">
										<div class="subtitle-form">
											<h3>{{group.title}}</h3>
										</div>
										<div class="row"  *ngFor="let field of group.fields">
											<div class="col-12 text-white">
												<strong>{{field.key}}:</strong> {{field.value}}
											</div>
										</div>
									</div>
									<hr>
									<form [formGroup]="walletForm">
										<div class="subtitle-form">
											<h3>chiavi di sicurezza</h3>
										</div>
										
										<div class="row">
											<div class="col-12 ">
												<input-string formControlName="secret" label="Chiave privata del tuo wallet"></input-string>
											</div>
										</div>
										
									</form>
									<button type="button" class="wizardBtn" awPreviousStep>INDIETRO</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!entityForm.valid" (click)="save(false)" >SALVA</button>
									<button id="btnNext1" type="button" class="wizardBtn" [disabled]="!canSaveForm" (click)="save(true)" >SALVA E CERTIFICA</button>
									<br>

								</div>
							</div>
						</div>
					</div>
				</aw-wizard-step>

			</aw-wizard>
        </div>
    </ng-container>
</base-page>