import { Injectable } from "@angular/core";
import { ClaimedNFT } from "app/models/profile.models";
import { Restaurant } from "app/models/restaurant.model";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { HttpService } from "./http.service";

declare var clientLib;

@Injectable({
	providedIn: 'root'
})
export class BlockChainService {
	constructor(private _httpService: HttpService) {
	}

	public getSignature(data: any, secret: string) {
		const message = data.cert_id+ JSON.stringify(data.data);
		const signature = clientLib.signData(secret, message);

		return {
			message: message,
			signature: signature
		}
	}

	public createWallet = (data: {pubkey: string}): Observable<any> => {
		let url = environment.services.newBlockchainBasePath + "/user/pubkey";
		return this._httpService.post(url,data,"text");
	}

	public certifyEntity = (data: any, secret: string): Observable<any> => {
		let sign = this.getSignature(data,secret);
		let url = environment.services.newBlockchainBasePath + "/v2/issue";
		data.signature = sign.signature;
		return this._httpService.post(url,data,"text");
	}

	public claimNft = (nft: ClaimedNFT): Observable<any> => {
		let url = environment.services.newBlockchainBasePath + "/v2/transfer";
		return this._httpService.post(url, {"cert_id": nft.cert_id, "azienda": nft.domain}, "text")
	}
}